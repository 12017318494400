import { Component, OnInit } from '@angular/core';
import { BrandService } from '../../services/brand.service';
import * as validator from '../../validations/register-validation'; //validation
import Swal from "sweetalert2"
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  public email: any;
  public name: any = '';
  public message: any = ''; 
  public domains: any = '';
  public website: any = '';
  public validationErrors: any = {}; // Error object



  constructor(public brandService: BrandService, private recaptchaV3Service: ReCaptchaV3Service,) { }
 

  sendForm() {
    let obj = {
      email: this.email,
      name: this.name,
      message: this.message,
      domains: this.domains,
      website: this.website
    }
    let validate = validator.validatePartnerContact(obj);
    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.recaptchaV3Service.execute('partnercontact')
        .subscribe((token) => {
          this.validationErrors = {}
          var contactobj = {
            email: this.email,
            name: this.name,
            message: this.message, 
            domains: this.domains,
            website: this.website,
            token: token,
          }
          $('.customLoader').fadeIn();
          this.brandService.partnerContact(contactobj).subscribe((data: any) => {
            var res = data.response;
            if (data.status) {
              $('.customLoader').fadeOut();
              Swal.fire('Success!', res.msg, 'success');
              this.email = '';
              this.name = '';
              this.message = ''; 
              this.domains = '';
              this.website = '';
            } else {
              Swal.fire('Oops...', res.msg, 'error')
            }
          });
        }, (error) => {
          Swal.fire('Oops...', error.message, 'error')
        });
    }
  }

  ngOnInit(): void {
    $('.blmd-line .form-control').bind('focus', function () {
      $(this).parent('.blmd-line').addClass('blmd-toggled').removeClass("hf");
    })
      .bind('blur', function () {
        var val = $(this).val();
        if (val) {
          $(this).parent('.blmd-line').addClass("hf");
        } else {
          $(this).parent('.blmd-line').removeClass('blmd-toggled');
        }
      })
  }

}
