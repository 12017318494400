<main>
    <section class="sign-up-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <form class="sign-up-form login-width">
                        <div class="sign-up-form-head">
                            <h3>Certificate Request Form</h3>
                        </div>

                        <div class="sign-up-form-body">
                            <div class="row">

                                <div class="col-md-12">
                                    <div class="input-group blmd-form" >
                                        <div class="blmd-line" id="first_name_input">
                                            <input type="text" [(ngModel)]="first_name" name="first_name" id="first_name"
                                                class="form-control" required>
                                            <small class="error" *ngIf="validationErrors.first_name">{{
                                                validationErrors.first_name}}</small>
                                            <label class="blmd-label">First name <span>*</span></label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line" id="last_name_input">
                                            <input type="text" [(ngModel)]="last_name" name="last_name" id="last_name" class="form-control" required>
                                            <small class="error" *ngIf="validationErrors.last_name">{{
                                                validationErrors.last_name}}</small>
                                            <label class="blmd-label">Last name <span>*</span></label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line" id="email_input">
                                            <input type="email" [(ngModel)]="email" name="email" id="email" class="form-control" required>
                                            <small class="error" *ngIf="validationErrors.email">{{
                                                validationErrors.email}}</small>
                                            <label class="blmd-label">Email <span>*</span></label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line" id="business_name_input">
                                            <input type="text" [(ngModel)]="business_name" name="business_name" id="business_name" class="form-control"
                                                required>
                                            <small class="error" *ngIf="validationErrors.business_name">{{
                                                validationErrors.business_name}}</small>                                            
                                            <label class="blmd-label">Business name/Brand name <span>*</span></label>
                                            <p><b>Note :</b> This name will be displayed on the certificate if approved. This is the name you plan to use for your
                                                business.</p>
                                        </div>                                        
                                    </div>
                                </div> 
                                
                                <!-- <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line" id="general_domain_input">
                                            <input type="text" [(ngModel)]="general_domain" name="general_domain" id="general_domain" class="form-control">                                            
                                            <label class="blmd-label">Domain name</label>
                                        </div>
                                    </div>
                                </div> -->

                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line" id="industry_input">
                                            <input type="text" [(ngModel)]="industry" name="industry" id="industry"
                                                class="form-control">
                                            <label class="blmd-label">Industry/Sector</label>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="col-md-12">
                                    <div class="form-group searchdropdown" id="industry_input">
                                        <select class="form-control wide" [(ngModel)]="industry" name="industry">
                                            <option value="">Select industry/sector</option>
                                            <option value="industry1">industry1</option>
                                            <option value="industry2">industry2</option>
                                            <option value="industry3">industry3</option>
                                        </select>
                                        <small class="error" *ngIf="validationErrors.industry">{{
                                            validationErrors.industry}}</small>
                                    </div>
                                </div> -->                                

                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line" id="business_url_input">
                                            <input type="text" [(ngModel)]="business_url" name="business_url" id="business_url"
                                                class="form-control">
                                            <label class="blmd-label">Business website or URL</label>
                                        </div>
                                    </div>
                                </div>                                

                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line" id="business_one_sentence_input">
                                            <textarea type="text" maxlength = "1000" rows="5" height="150" [(ngModel)]="business_one_sentence" name="business_one_sentence" id="business_one_sentence" class="form-control"></textarea>
                                            <label class="blmd-label">Describe your business in one sentence</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line" id="business_goals_input">
                                            <textarea class="form-control" maxlength="1000" rows="5" height="150" [(ngModel)]="business_goals"
                                                name="business_goals"></textarea>
                                            <label class="blmd-label">What are your business goals? </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line" id="business_publicized_input">
                                            <textarea type="text" maxlength = "1000" rows="5" height="150" [(ngModel)]="business_publicized" name="business_publicized" id="business_publicized" class="form-control"></textarea>
                                            <label class="blmd-label">Where is your business publicized?</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line" id="main_competitors_input">
                                            <textarea type="text" maxlength = "1000" rows="5" height="150" [(ngModel)]="main_competitors" name="main_competitors" id="main_competitors" class="form-control"> </textarea>
                                            <label class="blmd-label">Who are your main competitors?</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line" id="potential_customers_input">
                                            <textarea type="text" maxlength = "1000" rows="5" height="150" [(ngModel)]="potential_customers" name="potential_customers" id="potential_customers" class="form-control"> </textarea>
                                            <label class="blmd-label">Who are your potential customers? </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line" id="customers_located_input">
                                            <textarea type="text" maxlength = "1000" rows="5" height="150" [(ngModel)]="customers_located" name="customers_located" id="customers_located" class="form-control"></textarea>
                                            <label class="blmd-label">Where are your customers mainly located? </label>
                                        </div>
                                    </div>
                                </div>

                                
                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line" id="comment_input">
                                            <textarea class="form-control" maxlength = "1000" rows="5" height="150" [(ngModel)]="comment"
                                                name="comment" ></textarea>
                                            <label class="blmd-label">Tell us anything else about your business </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <!-- Laoder -->
                                    <div class="customLoader" style="display: none;">
                                        <img src="assets/images/loader.svg" alt="" style="width:40px">
                                    </div>
                                    <!-- Laoder -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div>                            
                                    <button (click)="submitRequest()" class="btnsignup">Submit Request</button>                            
                                    </div>
                                </div>
                            </div>                            

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</main>