import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { PayPalScriptService, IPayPalConfig, ICreateOrderRequest, NgxPaypalComponent } from 'ngx-paypal';
import * as validator from '../../validations/register-validation'; //validation
import Swal from "sweetalert2"
import { BrandService } from '../../services/brand.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-leasetopay',
  templateUrl: './leasetopay.component.html',
  styleUrls: ['./leasetopay.component.scss']
})
export class LeasetopayComponent implements OnInit {

  public CartList = [];
  public CartPaypalList = [];
  public CartTotalPrice: any;
  isShown: boolean = true;
  public payPalConfig?: IPayPalConfig;
  public plan_id: any;

  public user: any;
  public fullName: String;
  public email: String;
  public phone: String;
  public comapny_name: String;
  public address: String;
  public suite: String;
  public city: String;
  public state: String;
  public postal_code: String;
  public country: String = '';
  public userinfo: any;
  public loading = false;

  
  isPaymentShown: boolean = true;
  

  @ViewChild("basic") basicSubscription?: NgxPaypalComponent;

  public validationErrors: any = {}; // Error object

  constructor(public authService: AuthenticationService,public brandService: BrandService,private payPalScriptService: PayPalScriptService,private router: Router) { 
    let leasecart = JSON.parse(localStorage.getItem('Lease_Cart'));
    this.CartList.push(leasecart);
    this.CartTotalPrice = JSON.parse(localStorage.getItem('Lease_Price'));

    let brandObj = {
      brand_id: leasecart[0].brand_id
    }

    this.brandService.getBrandPlanId(brandObj).subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        this.plan_id = res.data[0].plan_id;
        console.log(this.plan_id);
        this.initConfig(this.plan_id);

      }
    });
  }

  ngOnInit() {

    
    $('.blmd-line .form-control').bind('focus', function () {
      $(this).parent('.blmd-line').addClass('blmd-toggled').removeClass("hf");
    })
      .bind('blur', function () {
        var val = $(this).val();
        if (val) {
          $(this).parent('.blmd-line').addClass("hf");
        } else {
          $(this).parent('.blmd-line').removeClass('blmd-toggled');
        }
      })
    this.user = this.authService.getLoginUserData();

    if (this.user) {
      this.fullName = this.user.fullName
      this.email = this.user.email
      this.phone = this.user.phone
      this.country = this.user.country
      this.state = this.user.state
      this.comapny_name = this.user.comapny_name
      this.city = this.user.city
      this.address = this.user.address
      this.suite = this.user.suite
      this.postal_code = this.user.postal_code
    }

    

    this.CartPaypalList = this.CartList[0].map(data => {
      let retObj = {
        unit_amount: {
          currency_code: 'USD',
          value: 0
        }
      };
      retObj['id'] = data.brand_id;
      retObj['name'] = data.domain;
      retObj['quantity'] = '1';
      retObj['unit_amount']['currency_code'] = 'USD';
      retObj['unit_amount']['value'] = data.price;
      return retObj;
    });


    
    this.payPalScriptService.registerPayPalScript(
      {
        clientId: 'AeZu7Ax_73ZZ88R6TSxfR9EjP76C3_hqDx4KrLSLJw6IUP3TpUc6epeNd442dLWOQL3YYXZccBp7M0aQ',
        currency: "USD",
        vault: "true",
      },
      (payPalApi) => {
        if (this.basicSubscription) {
          this.basicSubscription.customInit(payPalApi);
        }        
      }
    );

  }

  initUser() {
    let fullName = this.fullName;
    let email = this.email;
    let phone = this.phone;
    let comapny_name = this.comapny_name;
    let address = this.address;
    let suite = this.suite;
    let city = this.city;
    let state = this.state;
    let postal_code = this.postal_code;
    let country = this.country;
    let registerObj = {
      fullName: fullName,
      email: email,
      address: address,
      suite: suite,
      city: city,
      state: state,
      phone: phone,
      postal_code: postal_code,
      country: country
    }
    let validate = validator.validatePayment(registerObj);

    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {

      this.validationErrors = {}

      let UserObj = {
        fullName: fullName,
        phone: phone,
        comapny_name: comapny_name,
        email: email,
        address: address,
        suite: suite,
        city: city,
        state: state,
        postal_code: postal_code,
        country: country,
        user: this.user
      }
      $('.customLoader').fadeIn();
      this.brandService.paymentAddressInfo(UserObj).subscribe((data: any) => {
        $('.customLoader').fadeOut();
        var res = data.response;
        if (data.status) {
          this.userinfo = res.data;
          this.isPaymentShown = !this.isPaymentShown;
          
          this.isShown = false;
        } else {
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }
  }

  private initConfig(plan_id: string): void {
    this.payPalConfig = {
      currency: 'USD',
      clientId: 'AeZu7Ax_73ZZ88R6TSxfR9EjP76C3_hqDx4KrLSLJw6IUP3TpUc6epeNd442dLWOQL3YYXZccBp7M0aQ',
      vault: "true",
      createSubscription: function (data, actions) {
        return actions.subscription.create({
          plan_id,
        });
      },      
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        //console.log('onApprove - transaction was approved, but not authorized', data, actions);
        console.log(data);
        

        var obj = {
          //payment: data,
          item: this.CartPaypalList,
          order_type: 'lease',
          total_price: this.CartTotalPrice,
          payment_id: data.orderID,
          user: this.userinfo
        }
        this.brandService.purchasePayment(obj).subscribe((data: any) => {
          var res = data.response;
          if (data.status) {
            localStorage.removeItem('CartPrice');
            localStorage.removeItem('Cart');
            $('.customLoader').fadeOut();
            if (res.data) {
              localStorage.setItem('Payment_User_id', JSON.stringify(res.data.user_id));
              window.location.href = res.data.home_url +"paymentbanktransfer"
            }
          } else {
            $('.customLoader').fadeOut();
            Swal.fire('Oops...', res.msg, 'error')
          }
        });

        // actions.order.get().then(details => {
        //   console.log('onApprove - you can get full order details inside onApprove: ', details);
        //  // $('.customLoader').fadeIn();
        //   var obj = {
        //     payment: details,
        //     item: this.CartList,
        //     order_type: 'lease',
        //     // user: this.userinfo
        //   }
        //   this.brandService.purchasePayment(obj).subscribe((data: any) => {
        //     var res = data.response;
        //     if (data.status) {
        //       localStorage.removeItem('CartPrice');
        //       localStorage.removeItem('Cart');
        //       $('.customLoader').fadeOut();
        //       if (res.data) {
        //         localStorage.setItem('Payment_User_id', JSON.stringify(res.data.user_id));
        //         window.location.href = "http://3.99.13.94:9191/paymentbanktransfer"
        //       }
        //     } else {
        //       $('.customLoader').fadeOut();
        //       Swal.fire('Oops...', res.msg, 'error')
        //     }
        //   });
         
        // });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        //  this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        Swal.fire('Oops...', 'Order cancelled, please try again.', 'error');
        console.log('OnCancel', data, actions);
      },
      onError: err => {
      //  Swal.fire('Oops...', 'Order cancelled, please try again.', 'error');
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    };
  } 

  redirectbanktransfer() {
    this.router.navigate(['/banktransfer']);
  }

}
