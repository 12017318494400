'use strict';
import * as Helper from '../globals/helper';

// server config
export function validateForgotPassword(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    // Get values
    var email = Obj.email;

    // Required
    if (Helper.isEmpty(Obj.email)) {
        errros['email'] = 'Email is required '
        returnValidator.isValid = false;
    } else if (!Helper.isValidEmail(Obj.email)) {
        errros['email'] = 'Email is not valid '
        returnValidator.isValid = false;
    }

    return returnValidator;
};
