<app-header></app-header>
<main>
  <section class="page-title-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="page-title">
            <h2>Frequently Asked Questions</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="faq-section">
    <div class="container">
      <div class="row">
          <div class="col-md-4 hidden-xs hidden-sm">
            <div class="faq-tabs">
              <ul class="nav">
                <li *ngFor="let type of faq_type_list;let i = index" class="title{{type.type_id}}">
                  <a data-toggle="tab" (click)="onClick(type.type_id)"><span>{{type.name}}</span></a>
                </li>
              </ul>
            </div>
          </div>
        
          <div class="col-md-8">
            <div class="faq-tabs-details">
              <div class="tab-content">
                <a data-toggle="tab" class="mob-tabs" href="#General"><span>General</span></a>
                <div *ngFor="let faqs of faq_all_list" id="{{faqs.type_id}}" class="tab-pane fade">
                  <div class="Faq-card">
                    <div class="panel-group" id="accordion">
                      <div *ngFor="let item of faqs.faq; let i = index" class="panel panel-default">
                        <div class="panel-heading" (click)="current = i">
                          <h4 class="panel-title">
                            <a [class.collapsed]='current == i' href="javascript:;">{{item.question}}
                              <i class="icon-minus"></i>
                              <i class="icon-plus"></i>
                            </a>
                          </h4>
                        </div>
                        <div id="collapse1" class="panel-collapse" [class.collapse]='current == i'>
                          <div class="panel-body">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="faq-text" [innerHtml]="item.answer">

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
       
      </div>
    </div>
  </section>
</main>

<app-footer></app-footer>