import { Component, OnInit } from '@angular/core';
import { BrandService } from '../../services/brand.service';

import { NgxZendeskWebwidgetModule, NgxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';


import * as $ from 'jquery'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public BrandList: any = [];
  public pageSizes = 8;
  pageOfItems: Array<any>;

  constructor(public brandService: BrandService) { }

  ngOnInit() {

    this.brandService.getActiveBrandList().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        this.BrandList = res.data;        
        // this.BrandList = BrandList.sort((a, b) => b.price - a.price);
        this.BrandList = res.data.sort((a, b) => a.display_order - b.display_order || b.price - a.price);
          // get xbot.com, vap.com, idle.com and each.com and put them 29th, 30th, 31st and 32nd position
          let xbot = this.BrandList.filter(x => x.domain.toLowerCase() === 'xbot.com');
          let vap = this.BrandList.filter(x => x.domain.toLowerCase() === 'vap.com');
          let idle = this.BrandList.filter(x => x.domain.toLowerCase() === 'idle.com');
          let each = this.BrandList.filter(x => x.domain.toLowerCase() === 'each.com');

          // remove xbot.com, vap.com, idle.com and each.com from the list
          this.BrandList = this.BrandList.filter(x => x.domain != 'xbot.com' && x.domain != 'vap.com' && x.domain != 'idle.com' && x.domain != 'each.com');


          if (xbot.length > 0) {
            console.log('xbot', xbot);
            this.BrandList = [
              ...this.BrandList.slice(0, 14),
              ...xbot,
              ...this.BrandList.slice(14)
            ]
          }

         
          if (vap) {
            this.BrandList = this.BrandList.filter(x => x.domain != 'vap.com');
            this.BrandList = [
              ...this.BrandList.slice(0, 15),
              ...vap,
              ...this.BrandList.slice(15)
            ]
          }

          
          if (idle) {
            this.BrandList = this.BrandList.filter(x => x.domain != 'idle.com');
            this.BrandList = [
              ...this.BrandList.slice(0, 16),
              ...idle,
              ...this.BrandList.slice(16)
            ]
          }

         
          if (each) {
            this.BrandList = this.BrandList.filter(x => x.domain != 'each.com');
            this.BrandList = [
              ...this.BrandList.slice(0, 17),
              ...each,
              ...this.BrandList.slice(17)
            ]
          }

        
      }
    });

  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    if (this.pageOfItems && this.pageOfItems.length > 0){
     var pos = $("#pagi-top").offset().top;
      window.scroll({ top: pos, left: 0, behavior: 'auto' })
    }
    this.pageOfItems = pageOfItems;
  }

}
