<app-header></app-header>

<main>
  <section class="sign-up-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <form class="sign-up-form">
            <div class="sign-up-form-head">
              <h3>Sign Up</h3>
            </div>
            <div class="sign-up-form-body">
              <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line">
                      <input type="text" [(ngModel)]="fullName" name="fullName"  class="form-control">
                      <small class="error" *ngIf="validationErrors.fullName">{{ validationErrors.fullName}}</small>
                      <label class="blmd-label">Full Name <span>*</span></label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line">
                      <input type="text" [(ngModel)]="userName" name="userName"  class="form-control">
                      <small class="error" *ngIf="validationErrors.userName">{{ validationErrors.userName}}</small>
                      <label class="blmd-label">Username <span>*</span></label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line">
                      <input type="email" [(ngModel)]="email" name="email" class="form-control">
                      <small class="error" *ngIf="validationErrors.email">{{ validationErrors.email}}</small>
                      <label class="blmd-label">Email <span>*</span></label>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line">
                      <input type="text" [(ngModel)]="phone" name="phone" id="phone" class="form-control">
                      <small class="error" *ngIf="validationErrors.phone">{{ validationErrors.phone}}</small>
                      <label class="blmd-label">Contact Phone</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line">
                      <input type="text" [(ngModel)]="twitteraccount" name="twitteraccount" class="form-control">
                      <label class="blmd-label">Twitter account</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line">
                      <input type="text" [(ngModel)]="portfoliourl" name="portfoliourl"  class="form-control">
                      <label class="blmd-label">Portfolio URL</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line">
                      <input type="password" [(ngModel)]="password" id="password" name="password"  class="form-control">
                      <span toggle="#password-field" (click)="passwordClick()" class="fa fa-fw fa-eye field-icon toggle-password"></span>
                      <small class="error" *ngIf="validationErrors.password">{{ validationErrors.password}}</small>
                      <label class="blmd-label">Password <span>*</span></label>
                      <div class="notice">
                        <i class="icon-info-button"></i>
                        <p><b>Note :</b> Your password must be at least 8 characters long including 1 uppercase letter.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line">
                      <input type="password" [(ngModel)]="confirm_password" name="confirm_password"  class="form-control">
                      <small class="error" *ngIf="validationErrors.confirm_password">{{ validationErrors.confirm_password}}</small>
                      <label class="blmd-label">Confirm Password <span>*</span></label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="checkbox-list">
                    <label class="control control--checkbox ripple" (ngModelChange)="onTermsChange($event)">I accept the legal <a target="_blank" routerLink="/cms-page/termsconditions">terms & conditions</a> of Brands Dot
                      Com Corporation
                      <input name="terms" [(ngModel)]="terms" type="checkbox" id="Pick" checked="checkbox">
                      <span class="control__indicator" id="chkValid"></span>
                    </label>
                  </div>
                  <small class="error" *ngIf="validationErrors.terms">{{ validationErrors.terms}}</small>
                  <button (click)="initRegister()" class="btnsignup">Sign up</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>

<ng-template #welcometemplate>
  <div class="modal-body">
    <div class="welcome-template">
      <div class="welcome-logo">
        <a routerLink="/" title="Brand">
          <img src="assets/img/brand_logo.png" alt="Sporto">
        </a>
      </div>
      <div class="welcome-user">Welcome, {{fullName}} ! </div>
      <div class="welcome-text">Thank you for joining Brand. You will receive a confirmation email from us shortly.
      </div>
      <div class="welcome-text2">Please follow the link in the email to activate your Brand account.</div>
      <div class="btn_dv text-center">
        <a routerLink="/login" title="Sign in" (click)="modalRef.hide()" class="btn_primary btn popupsignup">Go to Sign in</a>
      </div>
    </div>
  </div>
</ng-template>

<app-footer></app-footer>
