import { Component, OnInit } from '@angular/core';
import * as validator from '../../validations/register-validation'; //validation
import { BrandService } from '../../services/brand.service';
import Swal from "sweetalert2"
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  public email: any;
  public name: any = '';
  public message: any = '';
  public validationErrors: any = {}; // Error object

  constructor(public brandService: BrandService, private recaptchaV3Service: ReCaptchaV3Service,) { }

  ngOnInit(): void {
    $('.blmd-line .form-control').bind('focus', function () {
      $(this).parent('.blmd-line').addClass('blmd-toggled').removeClass("hf");
    })
      .bind('blur', function () {
        var val = $(this).val();
        if (val) {
          $(this).parent('.blmd-line').addClass("hf");
        } else {
          $(this).parent('.blmd-line').removeClass('blmd-toggled');
        }
      })
  }

  contactUs() {

    let obj = {
      email: this.email,
      name: this.name,
      message: this.message
    }
    let validate = validator.validateContact(obj);

    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.recaptchaV3Service.execute('contact')
        .subscribe((token) => {
          this.validationErrors = {}
          var contactobj = {
            email: this.email,
            name: this.name,
            message: this.message,
            token: token,
          }
          $('.customLoader').fadeIn();
          this.brandService.contactus(contactobj).subscribe((data: any) => {
            var res = data.response;
            if (data.status) {
              $('.customLoader').fadeOut();
              Swal.fire('Success!', res.msg, 'success');
              this.email = '';
              this.name = '';
              this.message = '';
            } else {
              Swal.fire('Oops...', res.msg, 'error')
            }
          });
        }, (error) => {
          Swal.fire('Oops...', error.message, 'error')
        });


    }
  }

}
