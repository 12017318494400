<app-header></app-header>
<main>
    <section class="page-title-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="page-title">                       
                        <!-- <h5>Showing <span>{{brandcount}}</span> Results</h5> -->
                        <h5>Certificate Registry</h5>
                    </div>
                </div>
            </div>
        </div>
    </section>

<section class="product-section">
    <div class="container">

        <div class="row">
            <div class="col-md-12">
            <div class="product-list">
                <div class="row">
                    <div class="col-md-12">
                        <div class="col-md-12">
                        <form (submit)="search()" class="certificate-form">
                            <input type="text" name="keyword" [(ngModel)]="keyword" class="form-control" placeholder="Search…">
                            <small class="error" *ngIf="validationErrors.keyword">{{ validationErrors.keyword}}</small>
                            <!-- <a (click)="search()" title="Filter"><img src="assets/images/filter.png" alt="filter"></a> -->
                            <button type="submit" class="certificate-search">Search</button>
                        </form>
                        </div>
                    </div>
                
                    <div class="col-md-12" >
                        <div class="col-md-12">                            
                            <div class="table-responsive table-brand" *ngIf="CertificateFilterList.length > 0">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Certificate Number</th>
                                            <th>Business Name/Brand Name</th>
                                            <th>Applicant's Name</th>
                                            <th>Date Issued</th>
                                            <th>Download Certificate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of pageOfItems; let i = index;">
                                            <td>#{{data.certificate_number}}</td>
                                            <td>{{data.business_name}}</td>
                                            <td>{{data.first_name}} {{data.last_name}}</td>
                                            <td>{{data.approval_date}}</td>
                                            <td><a target="_blank" href="{{data.certificate}}" download>Certificate</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="col-md-12">
                            <div class="no-text" *ngIf="CertificateFilterList.length == 0">
                                No results found.
                            </div>
                        </div>
                    </div>


                    
                    

                </div>

                <div class="row" *ngIf="CertificateFilterList.length > 0">
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 text-center">
                        <jw-pagination [pageSize]="pageSizes" [items]="CertificateFilterList" (changePage)="onChangePage($event)">
                        </jw-pagination>
                    </div>
                </div>

            </div>
            </div>
        </div>


       


        <!-- <div class="row">
            <div class="product-list">
                <div class="row">                   
                    <div *ngIf="CertificateFilterList.length > 0">
                        <div class="col-md-4 col-sm-6 col-xs-12 col-lg-4" *ngFor="let data of pageOfItems">
                            <div class="product-card xpaw-card">                                
                                <span>{{data.first_name}} {{data.last_name}}</span>
                                <span>{{data.order_date}}</span>
                                <span>{{data.domain}}</span>
                                <a href="{{data.certificate}}" download>Certificate</a>
                            </div>
                        </div>
                    </div>
                    <div class="no-text" *ngIf="CertificateFilterList.length == 0">
                        Oops! Nothing was found for this keyword. Please check your spelling or try another search term.
                    </div>
                </div>
                <div class="row" *ngIf="CertificateFilterList.length > 0">
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 text-center">
                        <jw-pagination [pageSize]="pageSizes" [items]="CertificateFilterList" (changePage)="onChangePage($event)">
                        </jw-pagination>            
                    </div>
                </div>
            </div>
         </div> -->


     </div>
</section>            
    


</main>    
<app-footer></app-footer>