import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';

import * as validator from '../../validations/reset-password-validation'; //validation
import * as env from '../../globals/env';

import Swal from 'sweetalert2'
declare var $: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public token: String;
  public password: String;
  public confirmPassword: String;
  public backgroundImg: any;

  public validationErrors: any = {}; // Error object

  constructor(
    public authService: AuthenticationService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    this.backgroundImg = sanitizer.bypassSecurityTrustStyle('url(assets/img/sing.jpg)');
  }

  ngOnInit() {
    // this.activatedRoute.params.subscribe((params: Params) => {
    //   this.token = params['token'];
    // });
    if (this.activatedRoute.snapshot.queryParams.resetcode) {
      this.token = this.activatedRoute.snapshot.queryParams.resetcode;
    }
  }

  initUpdatePassword() {
    let token = this.token;
    let password = this.password;
    let confirmPassword = this.confirmPassword;


    let Obj = {
      password: password,
      confirmPassword: confirmPassword,
    }

    let validate = validator.validateResetPassword(Obj);
    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {}

      let passwordObj = {
        reset_password_token: token,
        new_password: password
      };

      $('.customLoader').fadeIn();
      this.authService.resetPassword(passwordObj).subscribe((data: any) => {
        $('.customLoader').fadeOut();
        var res = data.response;
        if (data.status) {
          Swal.fire('Success!', res.msg, 'success')
          this.router.navigate(['/login']);
        } else {
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }
  }
  passwordClick() {

    $('.toggle-password').toggleClass("fa-eye fa-eye-slash");
    if ($('#password').attr("type") == "password") {
      $('#password').attr("type", "text");
    } else {
      $('#password').attr("type", "password");
    }
  }

}
