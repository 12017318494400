import { Component, OnInit } from '@angular/core';
import * as validator from '../../validations/register-validation'; //validation
import { Router } from '@angular/router';
import { BrandService } from '../../services/brand.service';
import Swal from "sweetalert2"
@Component({
  selector: 'app-certificate-main',
  templateUrl: './certificate-main.component.html',
  styleUrls: ['./certificate-main.component.scss']
})
export class CertificateMainComponent implements OnInit {

  public general_domain: any;
  public business_domain: any = '';
  public validationErrors: any = {}; // Error object

  constructor(private router: Router, public brandService: BrandService) { }

  ngOnInit(): void {

    let info = JSON.parse(localStorage.getItem('Domain_info'));

    if (info.type == 'general'){
      this.general_domain = info.domain
    }else{
      this.business_domain = info.domain
    }   

  }

  generalRequest() {
    let obj = {
      general_domain: this.general_domain,
      type:"general"
    }
    let validate = validator.validateGeneralDomain(obj);
    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {

      this.validationErrors = {}
      $('.customLoader').fadeIn();
      let objs = {
        domain: this.general_domain,
        type: "general"
      }
      this.brandService.checkDomain(objs).subscribe((data: any) => {
        var res = data.response;
        if (data.status) {
          $('.customLoader').fadeOut();
          localStorage.removeItem('Domain_info');
          let objs = {
            domain: this.general_domain,
            type: "general"
          }
          localStorage.setItem('Domain_info', JSON.stringify(objs));          
          this.router.navigate(['/certificate-form']);
        } else {
          $('.customLoader').fadeOut();
          Swal.fire('Oops...', "Domain name already certified. Please visit <a href='/certificates'>brands.com/certificates</a> to download a copy of the certificate.", 'error')
        }
      });
    }
  }

  businessRequest() {
    let obj = {
      business_domain: this.business_domain,
      type:"business"
    }
    let validate = validator.validateBusinessDomain(obj);
    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {}
      $('.customLoader').fadeIn();
      var objs = {
        domain: this.business_domain
      }
      this.brandService.checkDomain(objs).subscribe((data: any) => {
        var res = data.response;
        if (data.status) {
          $('.customLoader').fadeOut();
          localStorage.removeItem('Domain_info');
          let objs = {
            domain: this.business_domain,
            type: "business"
          }
          localStorage.setItem('Domain_info', JSON.stringify(objs));
          this.router.navigate(['/certificate-form'])
        } else {
          $('.customLoader').fadeOut();          
          Swal.fire('Oops...', "Domain name already certified. Please visit <a href='/'>brands.com/certificates</a> to download a copy of the certificate.", 'error')
        }
      });


      
    }
  }

}
