'use strict';
import * as Helper from '../globals/helper';
// server config
export function validateResetPassword(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    // Get values

    // Required
    if (Helper.isEmpty(Obj.password)) {
        errros['password'] = 'Password is required';
        returnValidator.isValid = false;
    } else if (Obj.password.length < 8) {
        errros['password'] = 'Password mustbe at least 8 char long including 1 uppercase letter';
        returnValidator.isValid = false;
    } else if (Obj.password !== Obj.confirmPassword) {
        errros['confirmPassword'] = 'Password and confirm password should match';
        returnValidator.isValid = false;
    }

    return returnValidator;
};
