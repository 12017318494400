import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class BrandService {



  authToken: any;
  user: any;
  baseUrl: any = environment.server_url;


  constructor(private http: HttpClient) { }



  getActiveBrandList() {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.get(this.baseUrl + '/api/brand/getActiveBrandList', { headers: headers });
  }

  getTopCategoryList() {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.get(this.baseUrl + '/api/category/getTopCategoryList', { headers: headers });
  }

  getActiveCategoryList() {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.get(this.baseUrl + '/api/category/getActiveCategoryList', { headers: headers });
  }

  searchApply(Obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/brand/searchApply', Obj, { headers: headers });
  }

  searchfirstnameapply(Obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/brand/searchfirstnameapply', Obj, { headers: headers });
  }

  searchindustryapply(Obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/brand/searchindustryapply', Obj, { headers: headers });
  }

  getFrontBrandDetails(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/brand/getFrontBrandDetails', obj, { headers: headers });
  }

  getCatName(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/brand/getCatName', obj, { headers: headers });
  }

  getBrandPlanId(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/brand/getBrandPlanId', obj, { headers: headers });
  }

  getOtherBrandDetails(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/brand/getOtherBrandDetails', obj, { headers: headers });
  }

  addToCart(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/brand/addToCart', obj, { headers: headers });
  }

  paymentAddressInfo(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/cart/paymentAddressInfo', obj, { headers: headers });
  }

  purchasePayment(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/cart/purchasePayment', obj, { headers: headers });
  }

  getUserLastOrderId(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/cart/getUserLastOrderId', obj, { headers: headers });
  }

  bankWireOrder(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/cart/bankWireOrder', obj, { headers: headers });
  }

  getFrontCMSPagesDetails(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/brand/getFrontCMSPagesDetails', obj, { headers: headers });
  }

  getAboutDetails() {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.get(this.baseUrl + '/api/category/getAboutDetails', { headers: headers });
  }

  getFrontFAQAllPages() {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.get(this.baseUrl + '/api/category/getFrontFAQAllPages', { headers: headers });
  }

  getFrontFAQType() {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.get(this.baseUrl + '/api/category/getFrontFAQType', { headers: headers });
  }

  getFrontFAQPages(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/category/getFrontFAQPages', obj, { headers: headers });
  }

  contactus(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/user/contactus', obj, { headers: headers });
  }

  serviceContact(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/user/serviceContact', obj, { headers: headers });
  }

  partnerContact(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/user/partnercontact', obj, { headers: headers });
  }

  purchaseContact(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/user/purchaseContact', obj, { headers: headers });
  }

  submitRequest(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/certificate/submitRequest', obj, { headers: headers });
  }

  getUserCertificateList(Obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/certificate/getUserCertificateList', Obj, { headers: headers });
  }

  checkDomain(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/certificate/checkDomain', obj, { headers: headers });
  }

}
