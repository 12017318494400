import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import * as validator from '../../validations/register-validation'; //validation
import { BrandService } from '../../services/brand.service';
import { AuthenticationService } from '../../services/authentication.service';
import Swal from "sweetalert2"
declare var $: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  public CartList = [];
  public CartPaypalList = [];
  public CartTotalPrice: any;
  isShown: boolean = true;
  isPaymentShown: boolean = true;
  isButtonShown: boolean = true;
  public payPalConfig?: IPayPalConfig;
  public finalAmount: number = 1;


  public user: any;
  public fullName: String;
  public email: String;
  public phone: String;
  public comapny_name: String;
  public address: String;
  public suite: String;
  public city: String;
  public state: String;
  public postal_code: String;
  public country: String = '';
  public userinfo:any;
  public loading = false;
  terms = false;

    
  public validationErrors: any = {}; // Error object
 

  constructor(private router: Router, public authService: AuthenticationService,public brandService: BrandService) { }

  ngOnInit() {

    $(document).ready(function () { 
      $('.blmd-line .form-control').bind('focus', function () {
        $(this).parent('.blmd-line').addClass('blmd-toggled').removeClass("hf");
      })
        .bind('blur', function () {
          var val = $(this).val();
          if (val) {
            $(this).parent('.blmd-line').addClass("hf");
          } else {
            $(this).parent('.blmd-line').removeClass('blmd-toggled');
          }
        })
    });

  

    this.user = this.authService.getLoginUserData();

    if (this.user) {
      this.fullName = this.user.fullName
      this.email = this.user.email
      this.phone = this.user.phone
      this.country = this.user.country
      this.state = this.user.state      
      this.comapny_name = this.user.comapny_name
      this.city = this.user.city
      this.address = this.user.address
      this.suite = this.user.suite
      this.postal_code = this.user.postal_code
    }

    let test = JSON.parse(localStorage.getItem('Cart'));
    this.CartList.push(test);
    this.CartPaypalList = this.CartList[0].map(data => {
      let retObj = {
        unit_amount: {
          currency_code: 'USD',
          value: 0
        }
      };
      retObj['id'] = data.brand_id;
      retObj['name'] = data.domain;
      retObj['quantity'] = '1';
      retObj['unit_amount']['currency_code'] = 'USD';
      retObj['unit_amount']['value'] = data.price;
      return retObj;
    });

    this.CartTotalPrice = JSON.parse(localStorage.getItem('CartPrice'));    
    this.initConfig();

  }

  private initConfig(): void {
    this.payPalConfig = {      
      currency: 'USD',
      clientId: 'AeZu7Ax_73ZZ88R6TSxfR9EjP76C3_hqDx4KrLSLJw6IUP3TpUc6epeNd442dLWOQL3YYXZccBp7M0aQ',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'USD',
              value: this.CartTotalPrice,
              breakdown: {
                item_total: {
                  currency_code: 'USD',
                  value: this.CartTotalPrice
                }
              }
            },
            items: this.CartPaypalList
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
        size: 'large'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
          $('.customLoader').fadeIn();
          var obj = {
           // payment: details,
            item: this.CartPaypalList,
            user: this.userinfo,
            order_type: 'purchase',
            payment_id: details.id
          }
          this.brandService.purchasePayment(obj).subscribe((data: any) => {
            var res = data.response;
            if (data.status) {
               localStorage.removeItem('CartPrice');
               localStorage.removeItem('Cart');
              $('.customLoader').fadeOut();
              if (res.data){
                localStorage.setItem('Payment_User_id', JSON.stringify(res.data.user_id));
                window.location.href = res.data.home_url +"paymentbanktransfer"
              }
            } else {
              $('.customLoader').fadeOut();
              Swal.fire('Oops...', res.msg, 'error')
            }
          });
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
      //  this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        Swal.fire('Oops...', 'Order cancelled, please try again.', 'error');
        console.log('OnCancel', data, actions);
      },
      onError: err => {
        Swal.fire('Oops...', 'Order cancelled, please try again.', 'error');
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    };
  }

  bankwire(){
    let fullName = this.fullName;
    let email = this.email;
    let phone = this.phone;
    let comapny_name = this.comapny_name;
    let address = this.address;
    let suite = this.suite;
    let city = this.city;
    let state = this.state;
    let postal_code = this.postal_code;
    let country = this.country;
    let terms = this.terms;
    let registerObj = {
      fullName: fullName,
      email: email,
      address: address,
      city: city,
      state: state,
      phone: phone,
      postal_code: postal_code,
      country: country,
      terms: terms
    }
    
    let validate = validator.validatePayment(registerObj);
    

    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {

      this.validationErrors = {}

      let UserObj = {
        fullName: fullName,
        phone: phone,
        comapny_name: comapny_name,
        email: email,
        address: address,
        suite: suite,
        city: city,
        state: state,
        postal_code: postal_code,
        country: country,
        user: this.user
      }
      
      $('.customLoader').fadeIn();
      this.brandService.paymentAddressInfo(UserObj).subscribe((data: any) => {       
        var res = data.response;
        if (data.status) {
          
          this.userinfo = res.data;
          let UserObj = {
            fullName: fullName,
            phone: phone,
            comapny_name: comapny_name,
            email: email,
            address: address,
            suite: suite,
            city: city,
            state: state,
            postal_code: postal_code,
            country: country,
            user: this.user
          }
          var obj = {
            total_price: this.CartTotalPrice,
            item: this.CartPaypalList,
            user: this.userinfo,
            order_type: 'bank_wire',
          }
          this.brandService.bankWireOrder(obj).subscribe((data: any) => {
            var res = data.response;
            if (data.status) {
              localStorage.removeItem('CartPrice');
              localStorage.removeItem('Cart');
              $('.customLoader').fadeOut();
              if (res.data) {
                localStorage.setItem('Payment_User_id', JSON.stringify(res.data.user_id));
                window.location.href = res.data.home_url+"banktransfer"
              }
            } else {
              $('.customLoader').fadeOut();
              Swal.fire('Oops...', res.msg, 'error')
            }
          });
        } else {
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }
  }

  onTermsChange(eve: any) {
    this.terms = !this.terms;
  }

  initUser() {
    let fullName = this.fullName;
    let email = this.email;
    let phone = this.phone;
    let comapny_name = this.comapny_name;
    let address = this.address;
    let suite = this.suite;
    let city = this.city;
    let state = this.state;
    let postal_code = this.postal_code;
    let country = this.country;
    let terms = this.terms;
    let registerObj = {
      fullName: fullName,
      email: email,
      address: address,
      city: city,
      state: state,
      phone: phone,
      postal_code: postal_code,
      country: country,
      terms: terms
    }
    
    let validate = validator.validatePayment(registerObj);
    
    

    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {

      this.validationErrors = {}

      let UserObj = {
        fullName: fullName,
        phone: phone,
        comapny_name: comapny_name,
        email: email,
        address: address,
        suite: suite,
        city: city,
        state: state,
        postal_code: postal_code,
        country: country,
        user: this.user
      }
      $('.customLoader').fadeIn();
      this.brandService.paymentAddressInfo(UserObj).subscribe((data: any) => {
        
        var res = data.response;
        if (data.status) {          
          this.userinfo = res.data;
          this.isPaymentShown = !this.isPaymentShown;
          this.isButtonShown = false;
          $('.customLoader').fadeOut();
        } else {
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }
  }

  toggleShow() {
    this.isShown = !this.isShown;
    this.isPaymentShown = true;
    this.isButtonShown = true;
  }

  redirectbanktransfer(){
    this.router.navigate(['/paymentbanktransfer']);
  }

  redirectpayment() {
    this.isPaymentShown = !this.isPaymentShown;
    this.isButtonShown = false;
  }

  


}
