import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authToken: any;
  user: any;
  baseUrl: any = environment.server_url;

  constructor(private http: HttpClient) { }

  loggedIn() {
    var token = localStorage.getItem('Brand_user_token');
    var user = localStorage.getItem('Brand_userData');
    if (token !== null && user !== null) {
      return true;
    } else {
      return false;
    }
  }

  getLoginUserData() {
    var user = JSON.parse(localStorage.getItem('Brand_userData'));
    return user;
  }


  getRoleName() {
    var user = JSON.parse(localStorage.getItem('Brand_userData'));
    return user.userRole;
  }

  loginUser(user) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/user/login', user, { headers: headers });
  }

  logout() {
    this.authToken = null;
    this.user = null;
    localStorage.removeItem('Brand_user_token');
    localStorage.removeItem('Brand_userData');   
  }

  resetPassword(Obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/user/reset-password', Obj, { headers: headers });
  }

  registerUser(obj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.post(this.baseUrl + '/api/user/registration', obj, { headers: headers });
  }

  // registerUser(user) {
  //   let headers = new HttpHeaders();
  //   headers.append('Content-type', 'application/json');
  //   return this.http.post(this.baseUrl + '/api/user/registration', user, { headers: headers });
  // }

  forgotPassword(user) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/user/forgot-password', user, { headers: headers });
  }

  storeUserData(token, user) {
    localStorage.setItem('Brand_user_token', token);
    localStorage.setItem('Brand_userData', JSON.stringify(user));
    if (token) {
      this.authToken = token;
    } else {
      this.authToken = "token";
    }
    this.user = user;
  }

  verifyEmail(obj) {
    let headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post(this.baseUrl + '/api/user/email-varification', obj, { headers: headers });
  }

}
