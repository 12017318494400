<app-header></app-header>
<section class="page-title-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="page-title">
            <h2>Brand and Business Consulting Questionnaire</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
<section class="video-intro">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="user-intro">
                    <div class="user-intro-icon">
                        <img src="assets/images/profile.png">
                    </div>
                    <div class="user-intro-text">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="video-frame">
                    <iframe src="https://www.youtube.com/embed/xNH2aIxGVeM?rel=0&modestbranding=1&showinfo=0" title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
            <div class="col-md-6">
                <div class="side-header-box">
                <h4 class="side-header">
                    Starting a business? 
                </h4>
                <p class="side-content">
                    Before you launch get out thoughts on the brand you're using to make sure you have a solid name! Have any other questions about your business, just let us know in the questionnaire below. We'll do our best to offer you good advice to help you in your business journey.
                    We offer a 60-day money back guarantee for any reason. If you're not satisfied with the consulting you receive from us, get 100% of your money back!
                </p>
              </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
            <div class="certi-box">
              <div class="col-md-6">
                <h2 class="page-title2">
                    If we think you're using a solid brand name for you business, we will issue you the Brands Dot Com Certificate! This certifies your name as high quality according to brands.com! 
                    You certificate will be added to a public registry available at brands.com/certificates
                </h2>
              </div>
              <div class="col-md-6">
                  <img src="assets/images/cert-img.svg" alt="">
              </div>
              <div class="col-md-12">
                <h4 routerLink="/register" class="domain-sales">Go to the Registry page</h4>
                  <h2 class="page-title3">
                    Start the questionnaire below!
                  </h2>
              </div>
            </div>
            </div>
          </div>
        <div class="row">
            <div class="col-md-12">
                <app-certificate-form class="brand-search-box"></app-certificate-form>
               
            </div>
            <div class="col-md-3"></div>
            <!-- <div class="col-md-6">
                <div class="intro-search">
                    <h3>BRANDS DOT COM <span>CERTIFICATE</span></h3>
                    <h2>For Businesses</h2>
                    <div class="form-group">
                        <input type="text" [(ngModel)]="business_domain" name="business_domain" id="business_domain" class="form-control" required>
                        <small class="error" *ngIf="validationErrors.business_domain">{{
                            validationErrors.business_domain}}</small>
                        <button class="btn-brands" (click)="businessRequest()">Go</button>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>
<app-footer></app-footer>