<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-5">
        <a href="#" title="Footer-logo" class="logo"><img src="assets/images/logo3.png" alt="Footer-logo"></a>
        <p>We have the highest quality premium domain names for sale in the world. These names are perfect for big
          brands and businesses looking to scale. Find the perfect name for your business on brands.com!
        </p>
      </div>
      <div class="col-md-5 links">


      </div>
      <div class="col-md-2 links">
        <h4>Quick Links</h4>
        <ul>
          <li><a routerLink="/contact-us" title="Contact Us">Contact</a></li>
          <li><a routerLink="/search" title="Search">Search</a></li>
          <li><a routerLink="/cms-page/termsconditions" title="Terms & Conditions">Terms & Conditions</a></li>
          <li><a routerLink="/cms-page/privacypolicy" title="Privacy Policy">Privacy Policy</a></li>
          <li><a routerLink="/faq" title="FAQ">FAQ</a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
<div class="bottom-footer">
  <span>© {{ year }} Brands Dot Com Corporation All rights reserved.</span>
</div>