import { Component, OnInit } from '@angular/core';
import { BrandService } from '../../services/brand.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public CatgeoryList: any = [];
  public checkLogin: boolean; 
  public user: any;
  public year: number = new Date().getFullYear();

  constructor(private router: Router,public brandService: BrandService, public authService: AuthenticationService) { }

  ngOnInit() {
    this.user = this.authService.getLoginUserData();
    

    this.brandService.getTopCategoryList().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        let CatgeoryList = res.data;
        this.CatgeoryList = CatgeoryList;
      }
    });
    
    this.checkLogin = this.authService.loggedIn();

  }

  initLogout() {
    this.authService.logout();
    this.router.navigate(['/']);
    this.checkLogin = this.authService.loggedIn();
    Swal.fire('Success!', "You've been logged out successfully", 'success');
    return false;
  }

  industrynavigate(slug){
    $('html, body').animate({ scrollTop: 0 }, 'fast');
    this.router.navigate(['/industryname/' + slug]);
  }

}
