import { Component, OnInit } from '@angular/core';
import { BrandService } from '../../services/brand.service';

@Component({
  selector: 'app-banktransfer',
  templateUrl: './banktransfer.component.html',
  styleUrls: ['./banktransfer.component.scss']
})
export class BanktransferComponent implements OnInit {

  public PaymentList = [];

  constructor(public brandService: BrandService) { }

  ngOnInit() {
    var user = JSON.parse(localStorage.getItem('Payment_User_id'));

    var obj = {
      user_id: user
    }

    this.brandService.getUserLastOrderId(obj).subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        this.PaymentList = res.data;
      }
    });
  }

  printPage() {
    window.print();
  }

  

}
