import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import * as validator from '../../validations/login-validation';


import Swal from "sweetalert2"
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public users: any;  
  public email: any;
  public password: any;
  public validationErrors: any = {};

  constructor(
    public authService: AuthenticationService,
    private router: Router
  ) {} 


  ngOnInit() {
    
   

    $('.blmd-line .form-control').bind('focus', function () {
      $(this).parent('.blmd-line').addClass('blmd-toggled').removeClass("hf");
    })
    .bind('blur', function () {
      var val = $(this).val();
      if (val) {
        $(this).parent('.blmd-line').addClass("hf");
      } else {
        $(this).parent('.blmd-line').removeClass('blmd-toggled');
      }
    })
    if (this.authService.loggedIn()) {
      this.router.navigate(['/']);
    }
  }
  onLoginSubmit() {
    let email = this.email;
    let password = this.password;

    let loginObj = {
      email: email,
      password: password,
    };

    let validate = validator.validateLogin(loginObj);
    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {}

      var userObj = {
        email: email,
        password: password
      }
      $('.customLoader').fadeIn();
      this.authService.loginUser(userObj).subscribe((data: any) => {
        $('.customLoader').fadeOut();
        var res = data.response;
        if (data.status) {
          if (res.data.userRole == 1) {
            Swal.fire('Oops...', 'Only user can login here', 'error');
          } else {
            this.authService.logout();
            this.authService.storeUserData(res.token, res.data);            
            this.router.navigate(['/']);
          }
        } else {
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }
  }

  passwordClick() {

    $('.toggle-password').toggleClass("fa-eye fa-eye-slash");
    if ($('#password').attr("type") == "password") {
      $('#password').attr("type", "text");
    } else {
      $('#password').attr("type", "password");
    }
  }
}
