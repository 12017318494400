<app-header></app-header>

<main>
  <!-- <div class="banner">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <i class="profile"><img src="assets/images/profile.png" alt="profile"></i>
          <h1>A message from Joshua Baker, CEO, Brands Dot Com Corporation</h1>
          <p>We’re excited to launch brands.com! We are a brands business different than the rest, because we ONLY sell
            high quality brands. For updates subscribe to my YouTube channel or follow my Instagram.</p>
          <a routerLink="/search" class="btn-pink">Start Your Search</a>
        </div>
        <div class="col-md-6">
          <div class="banner-img">
            <img src="assets/images/banner.png" alt="banner">
            <a href="#" title="Play" class="play" type="button" data-toggle="modal" data-target="#video-popup"> <img
                src="assets/images/play.svg" alt="play"></a>
          </div>
        </div>
      </div>
    </div>
  </div> -->


  <div class="banner">
    <div class="container">
      <div class="row banner-row">
        <div class="col-md-12">
          <div class="banner-profile" style="margin-bottom: 25px;">
            <!-- <div class="img-box">
              <img src="assets/images/profile.png" alt="profile">
            </div> -->
            <div class="img-text">
              <!-- <p style="padding-bottom: 7px;">January 25th 2023</p> -->
              <h1>
                <span>
                  Brands.com is a marketplace for only the most distinctive and memorable brand names in the world. We
                  also offer video
                  marketing and brand services. Here are some of the names available on Brands.com
                </span>
              </h1>
              <!-- <a style="color:#5ba1f1; font-size: 22px; font-weight: bold;"
                href="http://www.youtube.com/channel/UCM5m2LiIHulWv-BS9Gfw67g?sub_confirmation=1" target="_blank">And be
                sure to Subscribe to my YouTube channel!</a> -->
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="banner-video">
            <iframe src="https://www.youtube.com/embed/4rY1BX7asZo" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>
      </div>



    </div>
  </div>


  <div class="seller">
    <div class="container" id="pagi-top">
      <em></em>
      <h2>Domain names for sale:</h2>
      <p></p>
      <div class="row">
        <div class="col-md-3 col-lg-3 col-sm-6 col-xs-12" *ngFor="let data of pageOfItems">
          <i>
            <span>
              <img routerLink="/{{ data.domain }}" class="img-curs" *ngIf="data.images.name !== ''"
                src="{{data.images.name}}" alt="images">
              <img *ngIf="data.images.name === ''" src="images/maira.png" alt="images">
            </span>

            <a routerLink="/{{ data.domain }}" title="{{data.domain}}"
              class="site g-site">{{data.domain}}<em class="data-price">{{data.price | currency}}</em></a>


          </i>
        </div>
      </div>
      <jw-pagination [pageSize]="pageSizes" [items]="BrandList" (changePage)="onChangePage($event)"></jw-pagination>
      <h4 routerLink="/search" class="domain-sales">See all domains for sale</h4>
    </div>
  </div>
  <div class="frequent-cuestions">
    <div>
      <div routerLink="/faq" class="lower-header-faqs">FAQS</div>
      <h1 class="lower-header-title">Frequenty Asked Questions</h1>
      <div class="frequent-text"></div>
      <div class="main-questions-box">
        <h4 class="main-questions">Why buy a business name from brands.com?
          <h5 class="main-answers">
            The best brand names are ones that are easy to remember. That is our focus. Easy to remember domain names
            that are also very brandable. Names like this market the most efficiently and can effectively scale. On
            Brands.com you'll find many 4-letter .com domains such as 'ahba.com'. You'll also find many one word .com
            domains such as 'footage.com'. 4-letter .com domains and one word .com domains make up most of the
            Brands.com portfolio.
          </h5>
        </h4>
        <h4 class="main-questions">Why do you only sell .com domains?
          <h5 class="main-answers">
            Because .com is baked into our language and psychology. Everybody understands that when we say "Dot Com"
            we're talking about a website address. If you use a different TLD as a business name such a '.io' for
            example. Not everyone is going to understand what you mean. But everybody knows Dot Com. It is the most
            understood TLD in our language. The whole point of Brands.com is high quality brand names that market the
            most efficiently. That's why we decided to only sell .com domains on Brands.com
          </h5>
        </h4>
        <h4 class="main-questions">What do I get when I purchase a domain name from Brands.com?
          <h5 class="main-answers">
            You get the domain name itself of course which will be transferred to your registrar. You also get a
            business and marketing package. The contents of the package depends on the domain name. All domains include
            high quality logo files. Many domains also include marketing videos and animations. You keep the copyrights.
          </h5>
        </h4>
        <h4 class="main-questions">Can I negotiate the price for a domain name I want?
          <h5 class="main-answers">
            All prices are professionally set at what we believe are fair market prices. Therefore the listed prices are
            firm. However we may be able to give some value off the listed price for some domains.
          </h5>
        </h4>
        <h4 class="main-questions">How do I purchase a domain name from brands.com?
          <h5 class="main-answers">
            You can purchase domain names directly on our website. We use Escrow.com to facilitate our domain name
            transactions (fees paid). Escrow.com is an accredited Escrow service. The Brands.com domain name portfolio
            is also listed on major domain name marketplaces.
          </h5>
        </h4>
      </div>
      <h4 routerLink="/faq" class="domain-sales">See all frequently asked questions</h4>
    </div>
  </div>
</main>

<div class="modal fade" id="video-popup" tabindex="-1" role="dialog" aria-labelledby="video-popup" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="video-container">
          <iframe width="100%" height="315"
            src="https://www.youtube.com/embed/xNH2aIxGVeM?rel=0&modestbranding=1&showinfo=0" frameborder="0"
            allowfullscreen></iframe>
        </div>
      </div>

    </div>
  </div>
</div>

<app-footer></app-footer>