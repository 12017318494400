import { Component, OnInit } from '@angular/core';
import * as validator from '../../validations/register-validation'; //validation
import { BrandService } from '../../services/brand.service';
import { Router } from '@angular/router';
import Swal from "sweetalert2"
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';

@Component({
  selector: 'app-certificate-form',
  templateUrl: './certificate-form.component.html',
  styleUrls: ['./certificate-form.component.scss']
})
export class CertificateFormComponent implements OnInit {

  public email: any;
  public first_name: any = '';
  public last_name: any = '';
  public business_name: any = '';
  public certificate_name: any = '';
  public industry: any = '';
  public comment: any = '';
  public validationErrors: any = {}; // Error object
  public search_type: any = '';
  public general_domain: any = '';
  public business_one_sentence: any = '';
  public business_goals: any = '';
  public business_publicized: any = '';
  public main_competitors: any = '';
  public potential_customers: any = '';
  public customers_located: any = '';
  public business_url: any = '';

  // public CartTotalPrice: any = 50;
  // public payPalConfig?: IPayPalConfig;
  // public CartPaypalList = [];
  // public userinfo: any;

  constructor(public brandService: BrandService, private router: Router) { }

  ngOnInit(): void {



    $('.blmd-line .form-control').bind('focus', function () {
      $(this).parent('.blmd-line').addClass('blmd-toggled').removeClass("hf");
    }).bind('blur', function () {
      var val = $(this).val();
      if (val) {
        $(this).parent('.blmd-line').addClass("hf");
      } else {
        $(this).parent('.blmd-line').removeClass('blmd-toggled');
      }
    })



    // this.initConfig();
    let info = JSON.parse(localStorage.getItem('Domain_info'));
    if (info){
      this.email = info.email
      this.first_name = info.first_name
      this.last_name = info.last_name
      this.comment = info.comment
      this.business_name = info.business_name
      this.certificate_name = info.certificate_name
      this.business_url = info.business_url
      this.general_domain = info.general_domain
      this.industry = info.industry
      this.business_one_sentence = info.business_one_sentence
      this.business_goals = info.business_goals
      this.business_publicized = info.business_publicized
      this.main_competitors = info.main_competitors
      this.potential_customers = info.potential_customers
      this.customers_located = info.customers_located

      if (info.first_name) { $("#first_name_input").addClass("blmd-toggled"); }
      if (info.last_name) { $("#last_name_input").addClass("blmd-toggled"); }
      if (info.email) { $("#email_input").addClass("blmd-toggled"); }
      if (info.business_name) { $("#business_name_input").addClass("blmd-toggled"); }
      if (info.industry) { $("#industry_input").addClass("select-blmd-toggled"); }
      if (info.certificate_name) { $("#certificate_name_input").addClass("blmd-toggled"); }
      if (info.business_url) { $("#business_url_input").addClass("blmd-toggled"); }
      if (info.general_domain) { $("#general_domain_input").addClass("blmd-toggled"); } 
      if (info.comment) { $("#comment_input").addClass("blmd-toggled"); }
      if (info.business_one_sentence) { $("#business_one_sentence_input").addClass("blmd-toggled"); }
      if (info.business_goals) { $("#business_goals_input").addClass("blmd-toggled"); }
      if (info.business_publicized) { $("#business_publicized_input").addClass("blmd-toggled"); }
      if (info.main_competitors) { $("#main_competitors_input").addClass("blmd-toggled"); } 
      if (info.potential_customers) { $("#potential_customers_input").addClass("blmd-toggled"); }
      if (info.customers_located) { $("#customers_located_input").addClass("blmd-toggled"); }
    }
    



    // this.CartPaypalList = [{
    //   unit_amount: {
    //     currency_code: 'USD',
    //     value: 50
    //   },
    //   name: info.domain,
    //   quantity: 1
    // }];
  }

  submitRequest() {

    let obj = {
      email: this.email,
      first_name: this.first_name,
      last_name: this.last_name,
      comment: this.comment,
      business_name: this.business_name,      
      general_domain: this.general_domain
    }
    let validate = validator.validateCertificateForm(obj);
    //console.log(validate);
    
    if (!validate.isValid) {      
      this.validationErrors = validate.errors;
      var label = Object.keys(validate.errors)[0];
      $("input[name='" + label + "']").focus();
      $("select[name='" + label + "']").focus();
      return false;
    } else {
      this.validationErrors = {}
      // let objs = {
      //   domain: this.general_domain,
      //   type: "general"
      // }
      // this.brandService.checkDomain(objs).subscribe((data: any) => {
      //   var res = data.response;
      //   if (data.status) {
      //     $('.customLoader').fadeOut();
          localStorage.removeItem('Domain_info');
          let objs = {
            domain: this.general_domain,
            type: "general",
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            comment: this.comment.replace(/\r?\n/g, '<br />'),
            business_name: this.business_name,
            certificate_name: this.certificate_name,
            business_url: this.business_url,
            general_domain: '',
            industry: this.industry,
            business_one_sentence: this.business_one_sentence.replace(/\r?\n/g, '<br />'),
            business_goals: this.business_goals.replace(/\r?\n/g, '<br />'),
            business_publicized: this.business_publicized.replace(/\r?\n/g, '<br />'),
            main_competitors: this.main_competitors.replace(/\r?\n/g, '<br />'),
            potential_customers: this.potential_customers.replace(/\r?\n/g, '<br />'),
            customers_located: this.customers_located.replace(/\r?\n/g, '<br />')
          }
          localStorage.setItem('Domain_info', JSON.stringify(objs));
          this.router.navigate(['/certificate-payment']);
        // } else {
        //   $('.customLoader').fadeOut();
        //   Swal.fire('Oops...', "Domain name already certified. Please visit <a href='/certificates'>brands.com/certificates</a> to download a copy of the certificate.", 'error')
        // }
     // });
    }
  }

  // private initConfig(): void {

  //   this.payPalConfig = {
  //     currency: 'USD',
  //     clientId: 'AeZu7Ax_73ZZ88R6TSxfR9EjP76C3_hqDx4KrLSLJw6IUP3TpUc6epeNd442dLWOQL3YYXZccBp7M0aQ',
  //     createOrderOnClient: (data) => <ICreateOrderRequest>{
  //       intent: 'CAPTURE',
  //       purchase_units: [
  //         {
  //           amount: {
  //             currency_code: 'USD',
  //             value: this.CartTotalPrice,
  //             breakdown: {
  //               item_total: {
  //                 currency_code: 'USD',
  //                 value: this.CartTotalPrice
  //               }
  //             }
  //           },
  //           items: this.CartPaypalList
  //         }
  //       ]
  //     },
  //     advanced: {
  //       commit: 'true'
  //     },
  //     style: {
  //       label: 'paypal',
  //       layout: 'vertical',
  //       size: 'large'
  //     },
  //     onApprove: (data, actions) => {
  //       console.log('onApprove - transaction was approved, but not authorized', data, actions);
  //       actions.order.get().then(details => {

  //         console.log(details);

  //         console.log('onApprove - you can get full order details inside onApprove: ', details);
  //         $('.customLoader').fadeIn();
  //         let info = JSON.parse(localStorage.getItem('Domain_info'));
  //         var obj = {
  //           // payment: details,
  //           item: this.CartPaypalList,
  //           user: this.userinfo,
  //           order_type: 'purchase',
  //           payment_id: details.id,
  //           email: this.email,
  //           first_name: this.first_name,
  //           last_name: this.last_name,
  //           comment: this.comment,
  //           domain: info.domain,
  //           type: info.type
  //         }
  //         this.brandService.submitRequest(obj).subscribe((data: any) => {
  //           var res = data.response;
  //           if (data.status) {
  //             localStorage.removeItem('Domain_info');
  //             $('.customLoader').fadeOut();
  //             Swal.fire('Success!', "Your payment was successful. You will hear from us soon about your request. Thank You!", 'success');


  //           } else {
  //             $('.customLoader').fadeOut();
  //             Swal.fire('Oops...', res.msg, 'error')
  //           }
  //         });
  //       });
  //     },
  //     onClientAuthorization: (data) => {
  //       console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
  //       //  this.showSuccess = true;
  //     },
  //     onCancel: (data, actions) => {
  //       Swal.fire('Oops...', 'Order cancelled, please try again.', 'error');
  //       console.log('OnCancel', data, actions);
  //     },
  //     onError: err => {
  //       Swal.fire('Oops...', 'Order cancelled, please try again.', 'error');
  //       console.log('OnError', err);
  //     },
  //     onClick: (data, actions) => {
  //       console.log('onClick', data, actions);
  //     },
  //   };



  // }


}
