import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-activation-account',
  templateUrl: './activation-account.component.html',
  styleUrls: ['./activation-account.component.scss']
})
export class ActivationAccountComponent implements OnInit {

  public loading: Boolean = true;
  public status: any;
  public errorMessage: any;

  constructor(public authService: AuthenticationService,
    private router: Router,
    public activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.activationcode) {
      let verifyObj = {
        email_verify_token: this.activatedRoute.snapshot.queryParams.activationcode
      }
      this.authService.verifyEmail(verifyObj).subscribe((data: any) => {
        this.loading = false;
        var res = data.response;
        if (data.status) {
          this.status = true;
        } else {
          this.status = false;
          this.errorMessage = res.msg;
        }
      });
    }
    // this.activatedRoute.params.subscribe((params: Params) => {
    //   let token = params['token'];
    //   let verifyObj = {
    //     email_verify_token: token
    //   }
    //   this.authService.verifyEmail(verifyObj).subscribe((data: any) => {
    //     this.loading = false;
    //     var res = data.response;
    //     if (data.status) {
    //       this.status = true;
    //     } else {
    //       this.status = false;
    //       this.errorMessage = res.msg;
    //     }
    //   });
    // });
  }

}
