import { Component, OnInit } from '@angular/core';
import { BrandService } from '../../services/brand.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  public faq_type_list = [];
  public faq_all_list = [];
  faq: any = [];

  constructor(public brandService: BrandService,) { }

  ngOnInit() {
    $(".1").addClass('active');
    this.brandService.getFrontFAQType().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {      
        this.faq_type_list = res.data;
        
      }
    });

    this.brandService.getFrontFAQAllPages().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {        
        this.faq_all_list = res.data;
        setTimeout(() => {                           // <<<---using ()=> syntax
          $(".title1").addClass('active');
          $("#1").addClass('in active');
        }, 50);
        
      }
    });
    
   
  }

  onClick(id){
    for (var i = 0; i < this.faq_type_list.length; i++) {
      $("#" + i).removeClass('in active');
    }
    $("#" + id).addClass('in active');
  }

  // onclick(){
  //   this.brandService.getFrontFAQPages().subscribe((data: any) => {
  //     var res = data.response;
  //     if (data.status) {
  //       console.log(res.data);
  //       this.faq_list = res.data;

  //     }
  //   });
  // }

}
