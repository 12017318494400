'use strict';
import * as Helper from '../globals/helper';

// server config
export function validateLogin(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    // Get values
    var email = Obj.email;
    var password = Obj.password;
    var myRecaptcha = Obj.myRecaptcha

    // Required
    if (Helper.isEmpty(email)) {
        errros['email'] = 'Email is required '
        returnValidator.isValid = false;
    } else if (!Helper.isValidEmail(email)) {
        errros['email'] = 'Email is not valid '
        returnValidator.isValid = false;
    }
    if (Helper.isEmpty(password)) {
        errros['password'] = 'Password is required '
        returnValidator.isValid = false;
    }

    return returnValidator;
};


// server config
export function validateSearch(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };
    // Get values
    var keyword = Obj.keyword;    

    // Required
    if (Helper.isEmpty(keyword)) {
        errros['keyword'] = 'Search is required '
        returnValidator.isValid = false;
    }

    return returnValidator;
};


