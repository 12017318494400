import { Component, OnInit } from '@angular/core';
import { BrandService } from '../../services/brand.service';
import * as validator from '../../validations/login-validation';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';

@Component({
  selector: 'app-certificate-list',
  templateUrl: './certificate-list.component.html',
  styleUrls: ['./certificate-list.component.scss']
})
export class CertificateListComponent implements OnInit {

  public pageSizes = 15;
  pageOfItems: Array<any>;
  public CertificateFilterList: any = [];
  public brandcount = 0;
  public validationErrors: any = {}; // Error object
  public keyword: any;

  constructor(public brandService: BrandService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {

    let Obj = {
      keyword: ''
    };

    this.brandService.getUserCertificateList(Obj).subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        if (res.data.length > 0) {        
          this.CertificateFilterList = [];
          this.CertificateFilterList = res.data;
          this.brandcount = res.data.length;
        }
      } else {        
        this.CertificateFilterList = [];
        this.brandcount = 0;
      }
    });

  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  search() {
    let Obj = {
      keyword: this.keyword
    };
    // let validate = validator.validateSearch(Obj);
    // if (!validate.isValid) {
    //   this.validationErrors = validate.errors;
    //   return false;
    // } else {
    //   this.validationErrors = {}
      var keyword = this.keyword;
      

      this.brandService.getUserCertificateList(Obj).subscribe((data: any) => {
        var res = data.response;
        if (data.status) {
          if (res.data.length > 0) {
            this.CertificateFilterList = [];
            this.CertificateFilterList = res.data;
            this.brandcount = res.data.length;
          }
        } else {
          this.CertificateFilterList = [];
          this.brandcount = res.data.length;
        }
      });
      
    
   // }

    
  }

}
