import { Component, OnInit, ViewChild, ElementRef, TemplateRef, NgModule } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { BrandService } from '../../services/brand.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, of, timer } from 'rxjs';
import { IvyCarouselModule } from 'angular-responsive-carousel';

// import * as $ from 'jquery'
import Swal from "sweetalert2"



@Component({
  selector: 'app-brand-detail',
  templateUrl: './brand-detail.component.html',
  styleUrls: ['./brand-detail.component.scss']
})
export class BrandDetailComponent implements OnInit {

  public brand_id: any;
  public brand_domain: any;
  public brand: any;
  public name: any = '';
  public domain: String;
  public buylink: any = '';
  public price: any;
  public bio: any;
  public comments: any;
  public category: any;
  public leaseToOwnPrice: any;
  public video: any;
  public keyword_list = [];
  public category_list = [];
  public audio = [];
  public videofile = [];
  public images = [];
  public certificate = [];
  public BrandList = [];
  public slidershow = false;

  public brand_status: any;
  public total_letter: any;
  public extension: any;
  public leaseprice: any;

  public CartList = [];
  public CartTotalPrice: any;
  public sliderimage_path = '';
  public certificate_path = '';
  public videoimage_path = 'assets/images/Video.png';
  public slideraudio_path = 'assets/images/Audio.png';
  public audio_path = '';
  public video_path: any = '';
  public first_media: boolean = false;
  public first_media_class = '';
  pageOfItems: Array<any>;

  public readyToOffer = false;
  public makeOfferLabel = "Make Offer";

  public offer = "";
  @ViewChild('offerInput') offerInput;

  @ViewChild('videoRef') videoRef: ElementRef;

  public hasOffer = false;

  constructor(public brandService: BrandService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private sanitization: DomSanitizer,
  ) {

  }


  ngOnInit() {
    try {
      $('.product-banner-text button').click(function () {
        $('button.pinkbtn').removeClass('pinkbtn');
        $(this).addClass('pinkbtn');
      });

      this.activatedRoute.params.subscribe((params: Params) => {
        this.brand_id = params['id'];
        this.brand_domain = params['domain'];

        let brandObj = {
          brand_id: this.brand_id,
          brand_domain: this.brand_domain
        }

        this.brandService.getFrontBrandDetails(brandObj).subscribe((data: any) => {
          var res = data.response;
         
          if (data.status) {
            
            if (this.brand_id) {
              this.router.navigate(['/' + res.data.domain]);
            }

            var divs = document.querySelectorAll('.bigimage');
            for (var i = 0; i < divs.length; i++) {
              divs[i].classList.add('hide');
            }
            this.brand_id = res.data.id;
            this.brand_domain = res.data.domain;
            this.brand = res.data;
            this.name = this.brand.name;
            this.domain = this.brand.domain;
            this.buylink = this.brand.buylink;
            this.price = this.brand.price;
            this.offer = this.brand.price;
            this.bio = this.brand.bio;
            this.comments = this.brand.comments;
            this.keyword_list = this.brand.keyword_list;
            this.category_list = this.brand.category_list;
            this.leaseprice = this.brand.leaseprice;
            this.videofile = this.brand.videofile;
            this.certificate = this.brand.certificate;
            this.images = this.brand.images;
            this.audio = this.brand.audio;
            this.first_media = false;
            this.leaseToOwnPrice = Math.round(this.brand.price / 96);
            this.readyToOffer = true;

            // if (this.brand.brand_status !== 'available') {
            //    this.router.navigate(['/']);
            // }


            if (this.brand.videofile.length > 0) {
              this.video = '';
              this.first_media = true;
              this.first_media_class = 'videofile';
            } else {
              if (this.brand.video) {
                this.video = this.sanitization.bypassSecurityTrustResourceUrl(this.brand.video);
                this.first_media = true;
                this.first_media_class = 'videolink';
              } else {
                this.video = '';
                this.video_path = '';
              }
            }

            if (this.brand.images.length > 0) {
              if (!this.first_media) {
                this.first_media = true;
                this.first_media_class = 'sliderimage';
              }
              this.sliderimage_path = this.brand.images[0].name;
            } else {
              this.sliderimage_path = '';
            }


            if (this.brand.audio.length > 0) {
              if (!this.first_media) {
                this.first_media = true;
                this.first_media_class = 'audiofile';
              }
              this.audio_path = this.brand.audio[0].name
            } else {
              this.audio_path = '';
            }

            if (this.brand.certificate.length > 0) {
              if (!this.first_media) {
                this.first_media = true;
                this.first_media_class = 'certificate';
              }
              this.certificate_path = this.brand.certificate[0].name
            } else {
              this.certificate_path = ''
            }


            if (this.first_media_class !== '') {
              setTimeout(() => {
                $("." + this.first_media_class).removeClass('hide');
              }, 10);
            }

            let brandNameObj = {
              brand_name: this.brand.name,
              brand_id: this.brand_id
            }
            this.slidershow = true;
            this.brandService.getOtherBrandDetails(brandNameObj).subscribe((datas: any) => {
              var res = datas.response;
              if (datas.status) {
                let BrandList = res.data;
                this.BrandList = BrandList;



              }
              // console.log(this.BrandList);
            });

          } else {
            this.router.navigate(['/']);
          }
        });
      });
    } catch (e) {
      window.location.href = '/';
    }
  }

  sendOffer() {
    if (this.brand.brand_type === 'catchy' && Number(this.offer) < Math.round(this.brand.price * 0.7)) {
      let msg = 'Offer should be greater than or equal to ' + Math.round(this.brand.price * 0.7);
      Swal.fire('Oops...', msg, 'error');
      // this.offer = this.brand.price;
      this.offerInput.nativeElement.focus();
      return
    }
    location.href = `/purchase/${this.brand_id}?type=offer&price=${this.offer}`;
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  sendOffer2(e) {
    if (e.keyCode == 13) {
      this.sendOffer();
    } else {
      this.makeOfferLabel = "Send Offer";
      this.hasOffer = true;
    }
  }

  mediaClick(type, data) {
    var divs = document.querySelectorAll('.bigimage');
    for (var i = 0; i < divs.length; i++) {
      divs[i].classList.add('hide');
    }
    if (type == 'sliderimage') {
      $(".sliderimagesrc").attr("src", data.name);
    }
    $("." + type).removeClass('hide');
    if (type == 'videofile') {
      // this.playVideo(this.videoRef.nativeElement);
    } else {
      this.videoRef.nativeElement.pause();
    }

  }

  playVideo(el) {
    el.play();
  }

  setVideoStatus(el, status) {
    var container = document.getElementById('videofile');
    if (status == 'playing') {
      container.classList.add('playing');

    } else {
      container.classList.remove('playing');
    }
  }

  addToCart(type, brand_id, domain, price) {

    if (type == 'purchase') {
      let items = JSON.parse(localStorage.getItem('Cart'));
      if (items) {
        const checkBrandid = items.some(item => item.brand_id == brand_id)
        if (checkBrandid) {
          var msg = 'You have already added this brand.'
          Swal.fire('Oops...', msg, 'error');
        } else {
          var obj = {
            type: type,
            brand_id: brand_id,
            domain: domain,
            price: price,
          }
          items.push(obj);
          localStorage.setItem('Cart', JSON.stringify(items));
          var priceold = JSON.parse(localStorage.getItem('CartPrice'));
          this.CartTotalPrice = priceold + price;
          localStorage.setItem('CartPrice', JSON.stringify(this.CartTotalPrice));
          this.CartList.push(obj)
          Swal.fire({
            title: "Success!",
            icon: "success",
            text: "You have successfully added brand into cart.",
            showCancelButton: true,
            cancelButtonColor: '#FF69A9',
            confirmButtonText: 'Checkout',
            confirmButtonColor: '#FF69A9',
            cancelButtonText: 'Keep Browsing',
          })
            .then((res) => {
              if (res.value) {
                this.router.navigate(['/payment']);
              }
              // else if (res.dismiss == 'cancel') {
              //   console.log('cancel');
              // }
            });
        }
      } else {
        var newobj = [{
          type: type,
          brand_id: brand_id,
          domain: domain,
          price: price,
        }]
        this.CartList.push(newobj);
        localStorage.setItem('Cart', JSON.stringify(newobj));
        localStorage.setItem('CartPrice', JSON.stringify(price));

        Swal.fire({
          title: "Success!",
          icon: "success",
          text: "You have successfully added brand into cart.",
          showCancelButton: true,
          cancelButtonColor: '#FF69A9',
          confirmButtonText: 'Checkout',
          confirmButtonColor: '#FF69A9',
          cancelButtonText: 'Keep Browsing',
        })
          .then((res) => {
            if (res.value) {
              this.router.navigate(['/payment']);
            }
            // else if (res.dismiss == 'cancel') {
            //   console.log('cancel');
            // }
          });
      }
    }

    if (type == 'lease') {
      localStorage.removeItem('Lease_Cart');
      localStorage.removeItem('Lease_Price');
      let leaseobj = [{
        type: type,
        brand_id: brand_id,
        domain: domain,
        price: price,
      }]
      localStorage.setItem('Lease_Cart', JSON.stringify(leaseobj));
      localStorage.setItem('Lease_Price', JSON.stringify(price));
      this.router.navigate(['/leasetopay']);
    }



  }

  industrynavigate(id) {
    $('html, body').animate({ scrollTop: 0 }, 'fast');
    this.router.navigate(['/' + id]);
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    if (this.pageOfItems && this.pageOfItems.length > 0) {
      var pos = $("#pagi-top").offset().top;
      window.scroll({ top: pos, left: 0, behavior: 'auto' })
    }
    this.pageOfItems = pageOfItems;
  }





}
