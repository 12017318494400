<app-header></app-header>

<main>
  <section class="page-title-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="page-title">
            <h2>Results for {{brandsearchtext}} in All Categories</h2>
            <h5>Showing <span>{{brandcount}}</span> Results</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="product-section">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-sm-4 col-xs-12 col-lg-4">

          <div class="seraech-field">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Filter By <span class="filterbuttons"><a (click)="clearall()" class="clear-all">Clear All</a><a
                        href="javascript:;" class="closebtn"><i class="fa fa-times"></i></a></span></label>
                  <i class="fa fa-search"></i>
                  <input name="search_keyword" [(ngModel)]="search_keyword" type="text" class="form-control"
                    placeholder="Search business names">
                </div>
                <div class="form-group">

                  <label>Sort By : {{filterSortBy}}</label>
                  <div class="radiobox">
                    <input type="radio" name="sort_by" [(ngModel)]="sort_by" value="relevance" />
                    <div class="ripple-container">
                      <div class="radio-off"></div>
                      <div class="radio-on"></div>
                      <div class="ripple-on"></div>
                    </div>
                    <label for="yes">Relevance</label>
                  </div>


                  <div class="radiobox">
                    <input type="radio" name="sort_by" [(ngModel)]="sort_by" value="date_added" />
                    <div class="ripple-container">
                      <div class="radio-off"></div>
                      <div class="radio-on"></div>
                      <div class="ripple-on"></div>
                    </div>
                    <label for="no">Date Added</label>
                  </div>

                  <div class="radiobox">
                    <input type="radio" name="sort_by" [(ngModel)]="sort_by" value="first_letter" />
                    <div class="ripple-container">
                      <div class="radio-off"></div>
                      <div class="radio-on"></div>
                      <div class="ripple-on"></div>
                    </div>
                    <label for="yes">First Letter Alphabetically</label>
                  </div>


                  <div class="radiobox">
                    <input type="radio" name="sort_by" [(ngModel)]="sort_by" value="price_low_to_high" />
                    <div class="ripple-container">
                      <div class="radio-off"></div>
                      <div class="radio-on"></div>
                      <div class="ripple-on"></div>
                    </div>
                    <label for="no">Price Low to High</label>
                  </div>

                  <div class="radiobox">
                    <input type="radio" name="sort_by" [(ngModel)]="sort_by" value="price_high_to_low" />
                    <div class="ripple-container">
                      <div class="radio-off"></div>
                      <div class="radio-on"></div>
                      <div class="ripple-on"></div>
                    </div>
                    <label for="yes">Price High to Low</label>
                  </div>
                </div>
                <div class="form-group">
                  <label>Brand Type:</label>
                  <div class="Brand-radiobox">
                    <div class="radio-toolbar">
                      <input type="radio" id="rblAll" name="brand_type" [(ngModel)]="brand_type" value="all" checked>
                      <label for="rblAll">All</label>
                    </div>
                    <div class="radio-toolbar">
                      <input type="radio" id="Keyword" name="brand_type" [(ngModel)]="brand_type" value="keyword">
                      <label for="Keyword">Keyword</label>
                    </div>
                  </div>
                </div>
                <div class="form-group searchdropdown">
                  <label>Search in:</label>
                  <select nice-select class="form-control wide" [(ngModel)]="category" name="category">
                    <option *ngFor="let cat of ActivecatgeoryList; let i = index" [value]="cat.id">
                      {{cat.category_name}}
                    </option>
                  </select>
                </div>
                <div class="form-group searchdropdown">
                  <label>Search Type:</label>
                  <select nice-select class="form-control wide" [(ngModel)]="search_type" name="search_type">
                    <option value="broad" selected="">Broad match</option>
                    <option value="contains">Contains</option>
                    <option value="starts">Starts with</option>
                    <option value="ends">Ends with</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6">
                <div class="form-group searchdropdown">
                  <label>Min Price:</label>
                  <select nice-select class="form-control wide" [(ngModel)]="min_price" name="min_price">
                    <option value="0">No min</option>
                    <option value="10">$10</option>
                    <option value="500">$500</option>
                    <option value="1000">$1000</option>
                    <option value="1500">$1500</option>
                    <option value="2000">$2000</option>
                    <option value="2500">$2500</option>
                    <option value="3000">$3000</option>
                    <option value="5000">$5000</option>
                    <option value="10000">$10000</option>
                    <option value="25000">$25000</option>
                    <option value="50000">$50000</option>
                    <option value="75000">$75000</option>
                    <option value="100000">$100000</option>
                    <option value="250000">$250000</option>
                    <option value="500000">$500000</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6">
                <div class="form-group searchdropdown">
                  <label>Max Price:</label>
                  <select nice-select class="form-control wide" [(ngModel)]="max_price" name="max_price">
                    <option value="0">No min</option>
                    <option value="10">$10</option>
                    <option value="500">$500</option>
                    <option value="1000">$1000</option>
                    <option value="1500">$1500</option>
                    <option value="2000">$2000</option>
                    <option value="2500">$2500</option>
                    <option value="3000">$3000</option>
                    <option value="5000">$5000</option>
                    <option value="10000">$10000</option>
                    <option value="25000">$25000</option>
                    <option value="50000">$50000</option>
                    <option value="75000">$75000</option>
                    <option value="100000">$100000</option>
                    <option value="250000">$250000</option>
                    <option value="500000">$500000</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6">
                <div class="form-group searchdropdown">
                  <label>Min Length:</label>
                  <select nice-select [(ngModel)]="min_length" name="min_length" class="form-control wide">
                    <option value="0">No min</option>
                    <option value="3">3 letters</option>
                    <option value="4">4 letters</option>
                    <option value="5">5 letters</option>
                    <option value="6">6 letters</option>
                    <option value="7">7 letters</option>
                    <option value="8">8 letters</option>
                    <option value="9">9 letters</option>
                    <option value="10">10 letters</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6">
                <div class="form-group searchdropdown">
                  <label>Max Length:</label>
                  <select nice-select [(ngModel)]="max_length" name="max_length" class="form-control wide">
                    <option value="0">No min</option>
                    <option value="3">3 letters</option>
                    <option value="4">4 letters</option>
                    <option value="5">5 letters</option>
                    <option value="6">6 letters</option>
                    <option value="7">7 letters</option>
                    <option value="8">8 letters</option>
                    <option value="9">9 letters</option>
                    <option value="10">10 letters</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                <button (click)="cancel()" class="canclebtn">cancel</button>
              </div>
              <div class="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                <button (click)="searchfirstnameapply()" class="applybtn">Apply</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8  col-sm-8 col-xs-12 col-lg-8">
          <div class="product-list">
            <div class="row">
              <div *ngIf="BrandFilterList.length > 0">
                <div class="col-md-4 col-sm-6 col-xs-12 col-lg-4" *ngFor="let data of pageOfItems">
                  <div class="product-card xpaw-card">
                    <div class="product-img">
                      <img *ngIf="data.images.name !== ''" src="{{data.images.name}}" alt="images">
                      <img *ngIf="data.images.name === ''" src="images/maira.png" alt="images">
                    </div>
                    <a routerLink="/branddetail/{{ data.brand_id }}">{{data.domain}}</a>
                    <span>${{data.price}}</span>
                  </div>
                </div>                
              </div>
              <div class="no-text" *ngIf="BrandFilterList.length == 0">
                Oops! Nothing was found for this keyword. Please check your spelling or try another search term.
              </div>

            </div>

            <div class="row" *ngIf="BrandFilterList.length > 0">
              <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 text-center">
              <jw-pagination [pageSize]="pageSizes" [items]="BrandFilterList" (changePage)="onChangePage($event)"></jw-pagination>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<app-footer></app-footer>