<app-header></app-header>

<main>
  <div class="banner">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <form class="sign-up-form">
            <!-- Laoder -->
            <div class="customLoader" *ngIf="loading == true">
              <img src="assets/images/loader.svg" alt="" width="40">
            </div>
            <!-- Laoder -->
            <div class="logo">
              <div *ngIf="status !== undefined">
                <div class="message" *ngIf="status == true">
                  <i class=""></i> Success! Your email has been verified.
                </div>
                <div class="message" *ngIf="status == false">
                  <i class="fa fa-times-circle"></i> Invalid Token.
                </div>
              </div>
              <a class="btn_primary btn button" routerLink="/login"> Sign In </a>
            </div>
          </form>
        </div>
        
       </div>
    </div>
  </div>
</main>        


<app-footer></app-footer>