import { Component, OnInit, ViewChild, ElementRef, TemplateRef, NgModule } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { BrandService } from '../../services/brand.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as validator from '../../validations/register-validation'; //validation
import { ReCaptchaV3Service } from 'ng-recaptcha';

// import * as $ from 'jquery'
import Swal from "sweetalert2"



@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {

  public brand_id: any;
  public brand: any;


  public email: any;
  public name: any = '';

  public message: any = '';
  public price: any = 0;

  public domain: any = '';

  public priceEnabled = false;
  public hidePrice = false;
  public title = "Purchase";

  public type = "purchase";

  public who_are_you = "";
  public inquiry_purpose = "";
  public social_links = "";

  public who_are_you_options = [
    { value: 'Individual', label: 'I am an individual' },
    { value: 'Business Owner', label: 'I am a business owner' },
    { value: 'Domain Investor', label: 'I am a domain investor' },
    { value: 'Domain Broker', label: 'I am a domain broker' },
    { value: 'Represent a Company', label: 'I represent a company' },
    { value: 'Represent a Non-Profit Org.', label: 'I represent a non-profit organization' },
    { value: 'Other', label: 'Other' },
  ];

  public inquiry_purpose_options = [
    { value: 'New Business', label: 'Launching a new business' },
    { value: 'Rebranding', label: 'Rebranding an existing website' },
    { value: 'Investment', label: 'Domain name investment' },
    { value: 'Redirecting domain\'s traffic', label: 'Redirecting domain\'s traffic' },
    { value: 'Aquiring matching .com ', label: 'Aquiring matching .com for my brand' },
    { value: 'Other', label: 'Other' },
  ];

  public validationErrors: any = {}; // Error object


  constructor(public brandService: BrandService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private sanitization: DomSanitizer,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) { }


  ngOnInit() {

    this.activatedRoute.params.subscribe((params: Params) => {
      this.activatedRoute.queryParams.subscribe((query: Params) => {
        this.brand_id = params['id'];
        this.type = query['type']
        if (!this.brand_id) {
          this.router.navigate(['/search']);
        }

        let brandObj = {
          brand_id: this.brand_id
        }

        this.brandService.getFrontBrandDetails(brandObj).subscribe((data: any) => {
          var res = data.response;
          if (data.status) {

            var divs = document.querySelectorAll('.bigimage');
            for (var i = 0; i < divs.length; i++) {
              divs[i].classList.add('hide');
            }

            this.brand = res.data;
            this.price = this.brand.price;
            this.domain = this.brand.domain;


            switch (this.type) {
              case 'offer':
                this.title = "Make Offer";
                this.priceEnabled = true;
                this.price = this.activatedRoute.snapshot.queryParamMap.get('price') ?? this.brand.price;
                break;
              case 'purchase':
                this.title = "Purchase";
                break;
              case 'lease':
                this.title = "Lease";
                this.price = this.brand.leaseprice;
                break;
              case 'lease-to-own':
                this.title = "Lease-to-Own";
                this.price = Math.round(this.brand.price / 60);
                this.priceEnabled = false;
                break;
              case 'contact':
                this.title = "Contact";
                this.priceEnabled = false;
                this.hidePrice = true;
                break;
              default:
                this.router.navigate(['/search']);
                return
            }



          } else {
            // Swal.fire('Oops...', res.msg, 'error').then(() => {
            //   this.router.navigate(['/search']);
            // });
            this.router.navigate(['/search']);
          }
        });
      });
    });
  }

  sendForm() {
    let obj = {
      email: this.email,
      name: this.name,
      message: this.message,
      domain: this.domain,
      price: this.price,
      brand_id: this.brand_id,
      option: this.type,
      inquiry_purpose: this.inquiry_purpose,
      social_links: this.social_links,
      who_are_you: this.who_are_you
    }
    let validate = validator.validatePurchase(obj, this.brand);

    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.recaptchaV3Service.execute('purchase')
        .subscribe((token) => {
          this.validationErrors = {}
          var contactobj = {
            email: this.email,
            name: this.name,
            message: this.message,
            domain: this.domain,
            price: this.price,
            brand_id: this.brand_id,
            option: this.type,
            who_are_you: this.who_are_you,
            inquiry_purpose: this.inquiry_purpose,
            social_links: this.social_links,
            token: token,
          }
          $('.customLoader').fadeIn();
          this.brandService.purchaseContact(contactobj).subscribe((data: any) => {
            var res = data.response;
            if (data.status) {
              $('.customLoader').fadeOut();
              Swal.fire('Success!', res.msg, 'success').then(() => {
                this.router.navigate(['/search']);
              });
            } else {
              Swal.fire('Oops...', res.msg, 'error')
            }
          });
        }, (error) => {
          Swal.fire('Oops...', error.message, 'error')
        });
    }
  }


}
