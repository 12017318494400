import { Component, OnInit } from '@angular/core';
import { BrandService } from '../../services/brand.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public title: any;
  public title_content: any;

  public secdmages: any;
  public secondImage: any;
  public sectionone_content: any;
  public sectiontwo_content: any;

  public first_title: any;
  public firstmages: any;
  public firstImage: any;
  public first_description: any;

  public second_title: any;
  public secondmages: any;
  public secondsImage: any;
  public second_description: any;

  public third_title: any;
  public thirdmages: any;
  public thirdsImage: any;
  public third_description: any;


  public thirdsection_maincontent_top: any;
  public thirdsection_maincontent_middle: any;
  public thirdsection_maincontent_bottom: any;
  public thirdsection_subcontent: any;
  public singmages: any;
  public singnatureImage: any;

  public ownername: any;
  public position: any;

  public about: any;

  public fourthsection_title: string;
  public fourthsection_maincontent: string;
  public fourthsection_display: boolean;
  public fourthsection_image: string;

  public fifthsection_title: string;
  public fifthsection_maincontent: string;
  public fifthsection_display: boolean;
  public fifthsection_image: string;

  public edit_sectionone_image: any;
  public edit_first_image: any;
  public edit_second_image: any;
  public edit_third_image: any;
  public edit_signature_image: any;



  public content: any;


  constructor(public brandService: BrandService) { }

  ngOnInit(): void {
    this.brandService.getAboutDetails().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {

        this.about = res.data;

        this.title = this.about.title
        this.title_content = this.about.title_content
        this.sectionone_content = this.about.sectionone_content
        this.sectiontwo_content = this.about.sectiontwo_content
        this.first_title = this.about.first_title
        this.first_description = this.about.first_description
        this.second_title = this.about.second_title
        this.second_description = this.about.second_description
        this.third_title = this.about.third_title
        this.third_description = this.about.third_description
        this.thirdsection_maincontent_top = this.about.thirdsection_maincontent_top
        this.thirdsection_maincontent_middle = this.about.thirdsection_maincontent_middle
        this.thirdsection_maincontent_bottom = this.about.thirdsection_maincontent_bottom
        this.thirdsection_subcontent = this.about.thirdsection_subcontent
        this.ownername = this.about.ownername
        this.position = this.about.position
        this.fourthsection_display = this.about.fourthsection_display;
        this.fourthsection_maincontent = this.about.fourthsection_maincontent;
        this.fourthsection_title = this.about.fourthsection_title;
        this.fifthsection_display = this.about.fifthsection_display;
        this.fifthsection_maincontent = this.about.fifthsection_maincontent;
        this.fifthsection_title = this.about.fifthsection_title;

        if (this.about.secondImage) {
          this.edit_sectionone_image = this.about.secondImage;
        }
        if (this.about.firstImage) {
          this.edit_first_image = this.about.firstImage;
        }

        if (this.about.secondsImage) {
          this.edit_second_image = this.about.secondsImage;
        }
        if (this.about.thirdsImage) {
          this.edit_third_image = this.about.thirdsImage;
        }
        if (this.about.singnatureImage) {
          this.edit_signature_image = this.about.singnatureImage;
        }

        if (this.about.fourthsection_image) {
          this.fourthsection_image = this.about.fourthsection_image;
        }

        if (this.about.fifthsection_image) {
          this.fifthsection_image = this.about.fifthsection_image;
        }

      }
    });
  }

}
