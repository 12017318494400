import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { BrandService } from '../../services/brand.service';
import * as validator from '../../validations/login-validation';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
// import * as $ from 'jquery'
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-headerwithoutcart',
  templateUrl: './headerwithoutcart.component.html',
  styleUrls: ['./headerwithoutcart.component.scss']
})
export class HeaderwithoutcartComponent implements OnInit {

  public ActivecatgeoryList: any = [];
  public keyword: any;
  public validationErrors: any = {}; // Error object
  public CartList = [];
  public CartTotalPrice: any;


  @ViewChild('carttemplate') carttemplateModal: TemplateRef<any>;
  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'cart-box', ignoreBackdropClick: true, keyboard: false });
  }

  constructor(private modalService: BsModalService, public brandService: BrandService, private router: Router) { }

  ngOnInit() {

    // let test = JSON.parse(localStorage.getItem('Cart'));
    // this.CartList.push(test);
    // this.CartTotalPrice = JSON.parse(localStorage.getItem('CartPrice'));

    this.brandService.getActiveCategoryList().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        this.ActivecatgeoryList = res.data;
      }
    });
    $("ul.inner").parent().addClass("brands-icon")
    $(".brands-msite-menu").click(function () {
      $(".brands-Mobile-Menu").addClass("open-brands-menu");
    });

    $(".brands-Mobile-Menu h3 a").click(function () {
      $(".brands-Mobile-Menu").removeClass("open-brands-menu");
    });

    $('.open-close-icon').click(function (e) {
      e.preventDefault();
      var $this = $(this);
      if ($this.next().hasClass('show-ul')) {
        $this.next().removeClass('show-ul');
        $this.parent().removeClass('brands-open');
        $this.parent().addClass('brands-icon');
        $this.next().slideUp(350);
      } else {
        $this.parent().parent().find('li .inner').removeClass('show-ul');
        $this.parent().parent().find('li .inner').slideUp(350);
        $this.parent().parent().find('li').removeClass('brands-open');
        $this.parent().parent().find('li').addClass('brands-icon');
        $this.next().toggleClass('show-ul');
        $this.parent().toggleClass('brands-icon');
        $this.parent().toggleClass('brands-open');
        $this.next().slideToggle(350);
      }
    });
  }

  search() {
    // let Obj = {
    //   keyword: this.keyword
    // };
    // let validate = validator.validateSearch(Obj);
    // if (!validate.isValid) {
    //   this.validationErrors = validate.errors;
    //   return false;
    // } else {
    //   this.validationErrors = {}
    //   var keyword = this.keyword;
    //   this.router.navigate(['/search'], { queryParams: { keyword: keyword } });
    // }

    var keyword = this.keyword;
    this.router.navigate(['/search'], { queryParams: { keyword: keyword } });
  }

  removeItem(cart) {
    for (let k = 0; k < this.CartList.length; k++) {
      for (let j = 0; j < this.CartList[k].length; j++) {
        if (this.CartList[k][j].brand_id == cart.brand_id) {
          delete this.CartList[k][j];
        }
      }
    }
    var filtered = this.CartList[0].filter(function (el) {
      return el != null;
    });
    this.CartList = [];
    this.CartList.push(filtered);
    var priceold = JSON.parse(localStorage.getItem('CartPrice'));
    this.CartTotalPrice = priceold - cart.price;

    localStorage.removeItem('Cart');
    if (this.CartList[0].length > 0) {
      localStorage.setItem('Cart', JSON.stringify(this.CartList[0]));
      localStorage.setItem('CartPrice', JSON.stringify(this.CartTotalPrice));
    } else {
      localStorage.clear();
    }
  }

  openCart() {
    let test = JSON.parse(localStorage.getItem('Cart'));
    this.CartList = [];
    this.CartList.push(test);
    this.CartTotalPrice = JSON.parse(localStorage.getItem('CartPrice'));
    this.openModal(this.carttemplateModal);
  }


  redirectPayment() {
    if (this.CartList && this.CartList[0] != null) {
      if (this.CartList[0].length > 0) {
        this.router.navigate(['/payment']);
        this.modalRef.hide();
      }
    }

  }

}
