<app-header></app-header>

<main>
    <section class="page-title-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="page-title">
                        <h2 *ngIf="info && info.title">{{info.title}}</h2>                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="about-section">
        <div class="container">
            <div class="row">               
                <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12">
                    <div class="about-text" *ngIf="info && info.description" [innerHTML]="info.description">                        
                    </div>
                </div>
            </div>
        </div>
    </section>                    
</main>

<app-footer></app-footer>