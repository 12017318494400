import { Component, OnInit } from '@angular/core';
import { BrandService } from '../../services/brand.service';
import * as validator from '../../validations/register-validation'; //validation
import Swal from "sweetalert2"
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-marketing-services',
  templateUrl: './marketing-services.component.html',
  styleUrls: ['./marketing-services.component.scss']
})
export class MarketingServicesComponent implements OnInit {

  public email: any;
  public name: any = '';
  public about: any = '';
  public serviceSelected: any = '';
  public validationErrors: any = {}; // Error object


  public services = [
    {
      label: "Image logo",
      value: "Image logo",
    },
    {
      label: "Video logo",
      value: "Video logo",
    },
    {
      label: "5-10 second animation",
      value: "5-10 second animation",
    },
    {
      label: "10-20 second animation",
      value: "10-20 second animation",
    },
    {
      label: "30 second animation",
      value: "30 second animation",
    },
    {
      label: "60+ animation",
      value: "60+ animation",
    },
    {
      label: "Other",
      value: "Other"
    }
  ]

  constructor(public brandService: BrandService, private recaptchaV3Service: ReCaptchaV3Service,) { }

  selectService(item) {
    this.serviceSelected = item.value
  }

  sendForm() {
    let obj = {
      email: this.email,
      name: this.name,
      about: this.about,
      service: this.serviceSelected
    }
    let validate = validator.validateServiceContact(obj);

    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.recaptchaV3Service.execute('servicecontact')
        .subscribe((token) => {
          this.validationErrors = {}
          var contactobj = {
            email: this.email,
            name: this.name,
            about: this.about,
            service: this.serviceSelected,
            token: token,
          }
          $('.customLoader').fadeIn();
          this.brandService.serviceContact(contactobj).subscribe((data: any) => {
            var res = data.response;
            if (data.status) {
              $('.customLoader').fadeOut();
              Swal.fire('Success!', res.msg, 'success');
              this.email = '';
              this.name = '';
              this.about = '';
              this.serviceSelected = '';
            } else {
              Swal.fire('Oops...', res.msg, 'error')
            }
          });
        }, (error) => {
          Swal.fire('Oops...', error.message, 'error')
        });
    }
  }

  ngOnInit(): void {
    $('.blmd-line .form-control').bind('focus', function () {
      $(this).parent('.blmd-line').addClass('blmd-toggled').removeClass("hf");
    })
      .bind('blur', function () {
        var val = $(this).val();
        if (val) {
          $(this).parent('.blmd-line').addClass("hf");
        } else {
          $(this).parent('.blmd-line').removeClass('blmd-toggled');
        }
      })
  }

}
