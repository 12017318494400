import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { BrandService } from '../../services/brand.service';

@Component({
  selector: 'app-cms-page',
  templateUrl: './cms-page.component.html',
  styleUrls: ['./cms-page.component.scss']
})
export class CmsPageComponent implements OnInit {

  public slug:any;
  public info: any;

  constructor(private router: Router,
    public brandService: BrandService,
    public activatedRoute: ActivatedRoute,) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.slug = params['slug'];

      let obj = {
        slug: this.slug
      }

      this.brandService.getFrontCMSPagesDetails(obj).subscribe((data: any) => {
        var res = data.response;
        if (data.status) {
         // console.log(res.data);
          this.info = res.data[0];
          
        }
      });   
    });  
  }

}
