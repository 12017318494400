import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import * as validator from '../../validations/register-validation'; //validation
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import Swal from 'sweetalert2'
declare var $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public fullName: String;
  public userName: String;
  public email: String;
  public password: String;
  public confirm_password: String;
  public phone: String;
  public twitteraccount: String;
  public portfoliourl: String;
  public confirmPassword: String;
  public validationErrors: any = {}; // Error object
  terms = false;

  selectedItems = [];
 
  @ViewChild('welcometemplate') welcometemplateModal: TemplateRef<any>;
  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg modal-dialog-centered', ignoreBackdropClick: true, keyboard: false });
  }

  constructor(
    private modalService: BsModalService,
    public authService: AuthenticationService,    
    private router: Router 
  ) {}

  ngOnInit() {   
    if (this.authService.loggedIn()) {
      this.router.navigate(['/']);
    }
    $('.blmd-line .form-control').bind('focus', function () {
      $(this).parent('.blmd-line').addClass('blmd-toggled').removeClass("hf");
    })
    .bind('blur', function () {
      var val = $(this).val();
      if (val) {
        $(this).parent('.blmd-line').addClass("hf");
      } else {
        $(this).parent('.blmd-line').removeClass('blmd-toggled');
      }
    })
  }


  initRegister() {
    let fullName = this.fullName;
    let userName = this.userName;
    let email = this.email;
    let phone = this.phone;
    let twitteraccount = this.twitteraccount;
    let portfoliourl = this.portfoliourl;
    let password = this.password;
    let confirm_password = this.confirm_password;
    let terms = this.terms;
    
    let registerObj = {
      fullName: fullName,
      userName: userName,
      email: email,
      phone: phone,
      twitteraccount: twitteraccount,
      portfoliourl: portfoliourl,
      password: password,
      confirm_password: confirm_password,
      terms: terms
    }
    let validate = validator.validateRegister(registerObj);  
    
    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {

      this.validationErrors = {}

      let registerUserObj = {
        fullName: fullName,
        userName: userName,
        email: email,
        phone: phone,
        twitteraccount: twitteraccount,
        portfoliourl: portfoliourl,
        password: password,
        userRole: 2
      }
      $('.customLoader').fadeIn();
      this.authService.registerUser(registerUserObj).subscribe((data: any) => {
        $('.customLoader').fadeOut();
        var res = data.response;
        if (data.status) {
          //Swal.fire('Success!', res.msg, 'success')
          this.openModal(this.welcometemplateModal);
         
        } else {
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }
  }

  hide() {
    this.modalRef.hide();
  }

  onTermsChange(eve: any) {
    this.terms = !this.terms;
  }

  passwordClick() {

    $('.toggle-password').toggleClass("fa-eye fa-eye-slash");    
    if ($('#password').attr("type") == "password") {
      $('#password').attr("type", "text");
    } else {
      $('#password').attr("type", "password");
    }
  };

}
