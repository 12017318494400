import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Directive, Renderer2, AfterViewInit, OnInit } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit  {
  title = 'BrandUserApp';
  constructor(private router: Router, private renderer: Renderer2) { }
  
  onDeactivate() {  
    // setTimeout(function () {
    //   var scrollingElement = document.scrollingElement || document.documentElement;
    //   scrollingElement.scrollTop = 2;
    //   //document.body.scrollTop = 0;
    // }, 100);
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
       // this.router.navigated = false;
        window.scroll({ top: 0, left: 0, behavior: 'auto' })
      }
    });
  }

  ngAfterViewInit() {
    let loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }

}
