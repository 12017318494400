<app-header></app-header>


<main>

    <section class="about-section">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-lg-6 col-xs-12 column-1">
                    <div class="banner-img">
                        <img [src]="edit_sectionone_image" alt="banner">
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-lg-6 col-xs-12 column-2">
                    <h2>{{title}}</h2>
                    <p>{{title_content}}</p>
                    <div class="about-text" [innerHTML]="sectionone_content"></div>
                    <!-- <div class="link-text"><a href="/search">Here!</a></div> -->
                </div>
            </div>
        </div>
    </section>

    <!-- <section class="product-business-purchase bg-light-pink">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2>{{sectiontwo_content}}</h2>
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 col-lg-4">
                            <div class="product-business-card">
                                <div class="product-business-card-img">
                                    <img [src]="edit_first_image">
                                </div>
                                <div class="product-business-card-text">
                                    <h3>{{first_title}}</h3>
                                    <p>{{first_description}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="ol-md-4 col-sm-4 col-xs-12 col-lg-4">
                            <div class="product-business-card">
                                <div class="product-business-card-img">
                                    <img [src]="edit_second_image">
                                </div>
                                <div class="product-business-card-text">
                                    <h3>{{second_title}}</h3>
                                    <p>{{second_description}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="ol-md-4 col-sm-4 col-xs-12 col-lg-4">
                            <div class="product-business-card">
                                <div class="product-business-card-img">
                                    <img [src]="edit_third_image">
                                </div>
                                <div class="product-business-card-text">
                                    <h3>{{third_title}}</h3>
                                    <p>{{third_description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->


    <section class="about-section">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-lg-6 col-xs-12 column-1">
                    <div class="banner-img">
                        <img [src]="edit_first_image" alt="banner">
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-lg-6 col-xs-12 column-2">
                    <h2>{{thirdsection_subcontent}}</h2>
                    <p>{{title_content}}</p>
                    <div class="about-text" [innerHTML]="thirdsection_maincontent_bottom"></div>
                    <!-- <div class="link-text"><a href="https://www.youtube.com/channel/UCM5m2LiIHulWv-BS9Gfw67g" target="_blank">check out my YouTube channel!</a></div> -->
                </div>
            </div>
        </div>
    </section>


    <section class="about-section" *ngIf="fourthsection_display">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-lg-6 col-xs-12 column-1">
                    <div class="banner-img">
                        <img [src]="fourthsection_image" alt="banner">
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-lg-6 col-xs-12 column-2">
                    <h2>{{fourthsection_title}}</h2>
                    <p>{{title_content}}</p>
                    <div class="about-text" [innerHTML]="fourthsection_maincontent"></div>
                </div>
            </div>
        </div>
    </section>

    <section class="about-section" *ngIf="fifthsection_display">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-lg-6 col-xs-12 column-1">
                    <div class="banner-img">
                        <img [src]="fifthsection_image" alt="banner">
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-lg-6 col-xs-12 column-2">
                    <h2>{{fifthsection_title}}</h2>
                    <p>{{title_content}}</p>
                    <div class="about-text" [innerHTML]="fifthsection_maincontent"></div>
                </div>
            </div>
        </div>
    </section>

</main>
<app-footer></app-footer>