<app-header></app-header>

<main>
  <section class="sign-up-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <form class="sign-up-form login-width">
            <div class="sign-up-form-head">
              <h3>Forgot Password</h3>
            </div>
            <div class="sign-up-form-body">
              <div class="row">

                <div class="col-md-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line blmd-toggled">
                      <input type="email" [(ngModel)]="email" name="email" id="email" value="tom.cruise@gmail.com"
                        class="form-control">
                      <small class="error" *ngIf="validationErrors.email">{{ validationErrors.email}}</small>
                      <label class="blmd-label">Email <span>*</span></label>
                    </div>
                  </div>
                </div>               
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button (click)="onForgotPasswordSubmit()" class="btnsignup">Submit</button>                  
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>

<app-footer></app-footer>


