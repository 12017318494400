import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { log } from 'util';

@Injectable()

export class AuthGuard implements CanActivate{
    constructor(private authService: AuthenticationService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot) {        
        if(this.authService.loggedIn()) {
            let roles = route.data["roles"] as Array<string>;
            let roleName = this.authService.getRoleName();
            if( roles == null || roles.indexOf(roleName) != -1) {
                return true;
            } else {  
                if (roleName === 1) {
                    this.router.navigate(['/']);
                } else if (roleName === 2) {
                    this.router.navigate(['/user/home']);
                } else{
                    this.router.navigate(['/coach/home']);
                }
            }
        } else  {
            this.router.navigate(['/']);
        }
    }
}
