<app-headerwithoutcart></app-headerwithoutcart>
<main>

  <section class="payment-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="payment-card">
            <h2>Payment: Bank Transfer</h2>
            <span class="note-line" *ngIf="PaymentList[0] && PaymentList[0].total_amount">please send payment of {{PaymentList[0].total_amount | currency}} USD to the provided below:</span>
            <div class="payment-note">

              <h3 *ngIf="PaymentList[0] && PaymentList[0].order_detail">Amount: <span>{{PaymentList[0].total_amount | currency}}</span></h3>
              <h4>To: <span>Brands Dot Com Corporation </span></h4>
            </div>
            <div class="bank-details">
              <h3>Bank Name: <span>TD Canada Trust</span></h3>
              <h3>Accoundt Number: <span>5245778</span></h3>
              <h3>Swift Code: <span>TDOMCATTOR</span></h3>
              <h3>Institution Number: <span>004</span></h3>
            </div>
            <div class="branch-address" *ngIf="PaymentList[0] && PaymentList[0].user && PaymentList[0].user.address != null">
              <h3>Branch Address:</h3>
              <p>{{PaymentList[0].user.address}}</p>
            </div>
            <div class="bank-nots">
              <p> Reference number: <span *ngIf="PaymentList[0] && PaymentList[0].payment_order_id">{{PaymentList[0].payment_order_id}}</span> !important Include this
                reference number in the comments Or reference section on the transfer so we can verify ayment</p>
            </div>

            <div>
              <p> Print or screenshot this page and take it to your bank teller. They'll know what to do! &nbsp;&nbsp;&nbsp;&nbsp;<button (click)="printPage()" class="print">Print</button></p>
              
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>