import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { BrandService } from '../../services/brand.service';

// import * as $ from 'jquery'

@Component({
  selector: 'app-industryname',
  templateUrl: './industryname.component.html',
  styleUrls: ['./industryname.component.scss']
})
export class IndustrynameComponent implements OnInit {

  public ActivecatgeoryList = [];
  public BrandFilterList: any = [];

  public search_keyword: any;
  public sort_by: any;
  public category: any;
  public category_name: any;
  public search_type: any;
  public min_price: any = 0;
  public max_price: any = 0;
  public min_length: any = 0;
  public max_length: any = 0;
  public brand_type: any = 'all';
  public filterSortBy: any;

  public brandcount = 0;
  public brandsearchtext;
  navigationSubscription: any;

  // table options
  public pageSizes = 15;
  pageOfItems: Array<any>;

  constructor(public brandService: BrandService, private router: Router,private activatedRoute: ActivatedRoute) {
    this.category_name = this.activatedRoute.snapshot.paramMap.get('cat');
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.category_name = this.activatedRoute.snapshot.paramMap.get('cat');
        this.search_keyword = '';
        this.sort_by = '';
        this.category = '';
        this.search_type = '';
        this.min_price = 0;
        this.max_price = 0;
        this.min_length = 0;
        this.max_length = 0;
        this.brand_type = 'all';
        this.searchindustryapply();          
      }
    });
    
    // this.activatedRoute.queryParams.subscribe(
    //   params => {
    //     console.log('in');        
    //     console.log(params);        
    //     this.search_keyword = params['keyword'];
    //     this.category_name = params['cat'];
    //     console.log(this.category_name);
    //     console.log(this.search_keyword);        
    //     this.searchfirstnameapply();
    //   }
    // )
  }

  ngOnInit() {
    this.brandService.getActiveCategoryList().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        this.ActivecatgeoryList = res.data;

      }
    });
    $('.seraech-field .form-group .buttonlinks a').on('click', function () {
      $(this).addClass('active').parent().siblings().children().removeClass('active');
    });
    $("header .search-grp a").click(function () {
      $(".seraech-field").addClass("open-filer");
    });
    $(".seraech-field .form-group a.closebtn ").click(function () {
      $(".seraech-field").removeClass("open-filer");
    });

  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  clearall() {

    this.search_keyword = '';
    this.sort_by = '';
    this.category = '';
    this.search_type = '';
    this.min_price = 0;
    this.max_price = 0;
    this.min_length = 0;
    this.max_length = 0;
    this.brand_type = 'all'
    this.searchindustryapply()
  }

  cancel() {
    this.search_keyword = '';
    this.sort_by = '';
    this.category = '';
    this.search_type = '';
    this.min_price = 0;
    this.max_price = 0;
    this.min_length = 0;
    this.max_length = 0;
    this.brand_type = 'all'
    this.searchindustryapply()
  }

  searchindustryapply() {
    $(".seraech-field").removeClass("open-filer");
    
    var objs = {
      slug: this.category_name
    }
    this.brandService.getCatName(objs).subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        console.log(res.data[0].category_name);
        
        this.brandsearchtext = res.data[0].category_name
      } 
    });


    var obj = {
      search_keyword: this.search_keyword,
      sort_by: this.sort_by,
      category: this.category,
      search_type: this.search_type,
      min_price: this.min_price,
      max_price: this.max_price,
      min_length: this.min_length,
      max_length: this.max_length,
      brand_type: this.brand_type,
      category_name: this.category_name,
    }
    this.brandService.searchindustryapply(obj).subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        if (res.data.length > 0) {
          if (this.sort_by) {
            if (this.sort_by == 'price_low_to_high') {
              this.filterSortBy = "Price Low to High"
            } else if (this.sort_by == 'price_high_to_low') {
              this.filterSortBy = "Price High to Low"
            } else if (this.sort_by == 'first_letter') {
              this.filterSortBy = "First Letter Alphabetically"
            } else if (this.sort_by == 'date_added') {
              this.filterSortBy = "Date Added"
            } else if (this.sort_by == 'relevance') {
              this.filterSortBy = "Relevance"
            } else {
              this.filterSortBy = this.sort_by
            }
          }
          this.BrandFilterList = res.data;
          this.brandcount = res.data.length;
        }else{
          this.filterSortBy = '';
          this.BrandFilterList = [];
          this.brandcount = res.data.length;
        }
      } else {
        this.filterSortBy = '';
        this.BrandFilterList = [];
        this.brandcount = res.data.length;
      }
    });
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.

    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

}


