<app-header></app-header>

<main>
  <section class="sign-up-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <form class="sign-up-form login-width">
            <div class="sign-up-form-head">
              <h3>login</h3>
            </div>
            <div class="sign-up-form-body">
              <div class="row">

                <div class="col-md-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line">
                      <input type="email" [(ngModel)]="email" name="email" id="email" value="tom.cruise@gmail.com"
                        class="form-control">
                      <small class="error" *ngIf="validationErrors.email">{{ validationErrors.email}}</small>
                      <label class="blmd-label">Email <span>*</span></label>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line">
                      <input type="password" [(ngModel)]="password" name="password" id="password" class="form-control">
                      <span toggle="#password-field" (click)="passwordClick()" class="fa fa-fw fa-eye field-icon toggle-password"></span>
                      <small class="error" *ngIf="validationErrors.password">{{ validationErrors.password}}</small>
                      <label class="blmd-label">Password <span>*</span></label>
                    </div>
                  </div>
                </div>
              </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-lg-6 col-xs-12">
                    <a routerLink="/forgot-password" class="forgot-link">Forgot Password?</a>
                  </div>
                  <div class="col-md-6 col-sm-6 col-lg-6 col-xs-12">
                    <span class="sign-up-link">New to Brand ?<a routerLink="/register">Sign up</a></span>
                  </div>
                  <div class="col-md-12">
                    <button (click)="onLoginSubmit()" class="btnsignup">Login</button>
                  </div>
                </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
