import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import * as env from '../../globals/env';
import * as validator from '../../validations/forgot-password-validation';

import Swal from 'sweetalert2'
declare var $: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public email: String;
  public validationErrors: any = {};
  

  constructor(
    public authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {

    $('.blmd-line .form-control').bind('focus', function () {
      $(this).parent('.blmd-line').addClass('blmd-toggled').removeClass("hf");
    })
    .bind('blur', function () {
      var val = $(this).val();
      if (val) {
        $(this).parent('.blmd-line').addClass("hf");
      } else {
        $(this).parent('.blmd-line').removeClass('blmd-toggled');
      }
    })

  }

  onForgotPasswordSubmit() {
    let email = this.email;

    let FPObj = {
      email: email,
    };

    let validate = validator.validateForgotPassword(FPObj);
    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {};
      $('.customLoader').fadeIn();

      let finalObj = {
        email: email
      };
      this.authService.forgotPassword(finalObj).subscribe((data: any) => {
        $('.customLoader').fadeOut();
        var res = data.response;
        if (data.status) {
          Swal.fire('Success!', res.msg, 'success');
          this.router.navigate(['/login']);
        } else {
          Swal.fire('Oops...', res.msg, 'error');
        }
      });

    }
  }

}

