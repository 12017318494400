<app-header></app-header>


<main>

    <div class="banner">
        <div class="container">
            <div class="row banner-row">
                <div class="col-md-12">
                    <div class="banner-profile">
                        <div class="img-text">
                            <h1>
                                <span>
                                    Sell your domains on Brands.com, the world's leading marketplace for high quality names! We specialize in ONLY <strong style="font-weight: 900;">TOP-TIER</strong> names such as short .coms.
                                </span>
                            </h1>
                            <p style="font-weight: normal;margin-top: 15px;padding: 0;">If you have a short .com domain, we would love to be your broker! Brands.com connects you with our vast network of business startups searching for great names.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <section class="page-title-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="page-text2 mt-5" style="padding-top: 20px;">
                        
                        <h2>We offer two partnership options:</h2>
                        <ol>
                            <li>1.  Direct partnership: We will list your name(s) on brands.com. ONLY 10% commission fee is charged if we sell your domain. However you must point your domain's nameservers to Brands.com. You can list your domain on other marketplaces.</li>
                            <li>2. Non direct partnership: We will list your name(s) on brands.com. 20% commission fee is charged if we sell your domain. You do not need to point your nameservers to Brands.com. You can list your domain on other marketplaces.</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="sign-up-section" style="border-top: none;">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <form novalidate="" class="sign-up-form login-width ">
                        <div class="sign-up-form-head">
                            <h3>Interested in partnership? </h3>
                        </div>
                        <div class="sign-up-form-body">
                            <div class="row">
                                <div class="col-md-12">
                                    Send us an email at <a href="mailto:joshua@brands.com">joshua@brands.com</a>, or
                                    fill out the form below and we will get back to you!
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line"><input type="text" placeholder="Your Name *" name="name"
                                                id="name" required [(ngModel)]="name" class="form-control ">
                                            <small class="error" *ngIf="validationErrors.name">{{
                                                validationErrors.name}}</small>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line"><input type="email" [(ngModel)]="email"
                                                placeholder="Email *" name="email" id="email" required
                                                class="form-control">
                                            <small class="error" *ngIf="validationErrors.email">{{
                                                validationErrors.email}}</small>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line"><input type="text" [(ngModel)]="domains"
                                                placeholder="Domains *" name="domains" id="domains" required
                                                class="form-control">
                                            <small class="error" *ngIf="validationErrors.domains">{{
                                                validationErrors.domains}}</small>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line"><input type="text" [(ngModel)]="website"
                                                placeholder="Website *" name="website" id="website" required
                                                class="form-control">
                                            <small class="error" *ngIf="validationErrors.website">{{
                                                validationErrors.website}}</small>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line"><textarea rows="6" [(ngModel)]="message"
                                                placeholder="Please let us know about yourself and your portfolio of domains. *"
                                                height="150" name="message" class="form-control"></textarea>
                                            <small class="error" *ngIf="validationErrors.message">{{
                                                validationErrors.message}}</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="customLoader" style="display: none;"><img src="assets/images/loader.svg"
                                            alt="" style="width: 40px;"></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button (click)="sendForm()" class="btnsignup">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>