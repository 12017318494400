<app-headerwithoutcart></app-headerwithoutcart>
<main>

  <section class="payment-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="payment-card">
            <h2>Payment Completed</h2>    
            
            <div class="bank-nots">
              <p>Order id : <span *ngIf="PaymentList[0] && PaymentList[0].payment_order_id">{{PaymentList[0].payment_order_id}}</span></p>
            </div>

            <div class="payment-note">
              <h3>User: <span *ngIf="PaymentList[0] && PaymentList[0].user">{{PaymentList[0].user.fullName}}</span></h3>
              <h3>Email: <span *ngIf="PaymentList[0] && PaymentList[0].user">{{PaymentList[0].user.email}}</span></h3>
            </div>

            <div class="payment-note">
              <div class="table-responsive" *ngIf="PaymentList[0] && PaymentList[0].order_detail">
                <table>
                  <tr>
                    <th>Domain name</th>
                    <th>Price</th>
                  </tr>
                  
                  <tr *ngFor="let order of PaymentList[0].order_detail">
                    <td>{{order.domain}}</td>
                    <td *ngIf="PaymentList[0].order_type == 'lease'">{{order.leaseprice | currency}}</td>
                    <td *ngIf="PaymentList[0].order_type !== 'lease'">{{order.price | currency}}</td>
                  </tr>
            
                  <tr>
                    <td>Total</td>
                    <td>{{PaymentList[0].total_amount | currency}}</td>
                  </tr>
                </table>
              </div>
            </div>
                     
            <div class="branch-address" *ngIf="PaymentList[0] && PaymentList[0].user && PaymentList[0].user.address != null">
              <h3>Address:</h3>
              <p>{{PaymentList[0].user.address}}</p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>