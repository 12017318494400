<header>
  <div class="container">
    <a routerLink="/" title="Brands" class="logo"><img src="assets/images/logo.png" alt="brand"></a>
    <nav>
      <ul>
        <li><a routerLink="/search" title="All Names">All Names</a></li>
        <li><a routerLink="/firstname" title="First Names">First Names</a></li>
        <li><a routerLink="/search" title="Industry Specific Names">Industry Specific Names</a>
          <div class="brand-mega-menu">
            <h4>Browse business names by industry</h4>
            <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12  brand-menu-list">
              <a routerLink="/industryname/{{cat.slug}}"
                *ngFor="let cat of ActivecatgeoryList"><span>{{cat.category_name}}</span></a>
            </div>
          </div>
        </li>
      </ul>
    </nav>
    <div class="search-grp">
      <input type="text" name="keyword" [(ngModel)]="keyword" class="form-control" placeholder="Search…">
      <a (click)="search()" title="Filter"><img src="assets/images/filter.png" alt="filter"></a>
    </div>
    <!-- <small class="error" *ngIf="validationErrors.keyword">{{ validationErrors.keyword}}</small> -->
    <a href="javascript:;" title="menu" class="menu visible-xs brands-msite-menu"><img src="assets/images/menu.svg"
        alt="menu"></a>
    
    <!-- <div class="user-profile">
      <a href="javascript:;">
        <img src="assets/images/profile.png">
      </a>
    </div> -->
  </div>
  <div class="search-grp mobile visible-xs">
    <input type="text" name="keyword" [(ngModel)]="keyword" class="form-control" placeholder="Search…">
    <a (click)="search()" title="Filter"><img src="assets/images/filter.png" alt="filter"></a>
  </div>
</header>






<div class="brands-Mobile-Menu">
  <h3><img src="assets/images/logo.png" alt="brand"> <a routerLink="/"><i class="fa fa-times-circle"></i></a></h3>
  <ul class="">
    <li><a routerLink="/search">All Names</a></li>
    <li><a routerLink="/firstname">First Names</a></li>
    <li><a class="toggle-list" routerLink="/search">Industry Specific Names</a><span class="open-close-icon"></span>
      <ul class="inner">
        <li *ngFor="let cat of ActivecatgeoryList">
          <a routerLink="/industryname/{{cat.slug}}">{{cat.category_name}}</a>
        </li>
      </ul>
    </li>
  </ul>
</div>