<app-header></app-header>


<main>

    <div class="banner">
        <div class="container">
            <div class="row banner-row">
                <div class="col-md-12">
                    <div class="banner-profile" style="margin-bottom: 25px;">
                        <div class="img-text">
                            <h1>
                                <span>
                                    We can make a video animation to help promote and explain your business! Animation
                                    is a great way to market your idea!
                                    Take a look at some of our work:
                                </span>
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="banner-video"><iframe src="https://www.youtube.com/embed/4rY1BX7asZo" frameborder="0"
                            allowfullscreen=""></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="page-title-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div>
                        <h3 class="page-text2">We offer a variety of options to fit every client's needs! Options
                            include short video logos. We also offer longer form
                            marketing video production. We can come up with an original script, or alternatively you can
                            provide us with a script
                            you'd like us to use. Fill out the form below and let us know what you're looking for! We
                            will follow up and send you an
                            estimated quote</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="sign-up-section" style="border-top: none;">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <form novalidate="" class="sign-up-form login-width ">
                        <div class="sign-up-form-head">
                            <h3>Services</h3>
                        </div>
                        <div class="sign-up-form-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line"><input type="text" placeholder="Your Name *" name="name"
                                                id="name" required [(ngModel)]="name" class="form-control ">
                                            <small class="error" *ngIf="validationErrors.name">{{
                                                validationErrors.name}}</small>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line"><input type="email" [(ngModel)]="email"
                                                placeholder="Email *" name="email" id="email" required
                                                class="form-control">
                                            <small class="error" *ngIf="validationErrors.email">{{
                                                validationErrors.email}}</small>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-12 blmd-form">
                                    <div class="blmd-line">
                                        <label>Select a
                                            Service
                                        </label>
                                        <div *ngFor="let item of services;let i = index">
                                            <label class="radio-selectt">
                                                <input type="radio" [checked]="item.value===serviceSelected"
                                                    (change)="selectService(item)" name="serviceSelected"
                                                    value="{{item.value}}" style="margin-right: 5px;"
                                                    class="">{{item.label}}</label>

                                        </div>
                                        <small class="error" *ngIf="validationErrors.service">{{
                                            validationErrors.service}}</small>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line"><textarea rows="6" [(ngModel)]="about"
                                                placeholder="Tell us about your business, your goals, and what you'd like to achieve with a marketing video. *"
                                                height="150" name="message" class="form-control"></textarea>
                                            <small class="error" *ngIf="validationErrors.about">{{
                                                validationErrors.about}}</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="customLoader" style="display: none;"><img src="assets/images/loader.svg"
                                            alt="" style="width: 40px;"></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button (click)="sendForm()" class="btnsignup">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>