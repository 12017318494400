<app-header></app-header>
<main>
    <section class="sign-up-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <form class="sign-up-form login-width">
                        <div class="sign-up-form-head">
                            <h3>Contact</h3>
                        </div>
                        <div class="sign-up-form-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="upper-text">
                                        <h4 class="upper-text">
                                            <!-- Please do not contact me with any offers for domain names.
                                             Domain names for sale on brands.com are set prices and 
                                             are non negotiable.
                                              Otherwise contact me here joshua@brands.com :) -->
						Contact our domain broker directly at <a href="mailto:joshua@brands.com">joshua@brands.com</a> for any domain name inquiries 
                                        </h4>
                                    </div>
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line">
                                            <input type="text" [(ngModel)]="name" name="name" id="name" class="form-control" required>
                                            <small class="error" *ngIf="validationErrors.name">{{ validationErrors.name}}</small>
                                            <label class="blmd-label">Name <span>*</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line">
                                            <input type="email" [(ngModel)]="email" name="email" id="email" value="tom.cruise@gmail.com" class="form-control" required>
                                            <small class="error" *ngIf="validationErrors.email">{{ validationErrors.email}}</small>
                                            <label class="blmd-label">Email <span>*</span></label>
                                        </div>
                                    </div>
                                </div>                                
                                <div class="col-md-12">
                                    <div class="input-group blmd-form">
                                        <div class="blmd-line">
                                            <textarea class="form-control" rows="6" height="150" [(ngModel)]="message" name="message"></textarea>
                                            <small class="error" *ngIf="validationErrors.message">{{ validationErrors.message}}</small>
                                            <label class="blmd-label">Message <span>*</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                <!-- Laoder -->
                                <div class="customLoader" style="display: none;">
                                    <img src="assets/images/loader.svg" alt="" style="width:40px">
                                </div>
                                <!-- Laoder -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button (click)="contactUs()" class="btnsignup">Submit</button>                                    
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>
