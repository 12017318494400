import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appCurrencyFormatter]'
})
export class CurrencyInputDirective {
  @Input()
  public value: string = '';
  private previousValue: string = '';
  constructor(private el: ElementRef) {

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.el.nativeElement.value = this.formatCurrency(this.el.nativeElement.value);
    }, 500);
  }

  @HostListener('focus', ['$event'])
  onFocus() {
    let value: string = this.el.nativeElement.value;

    if (value) {
      // Remove currency formatting on focus
      value = value.replace(/[^0-9.]/g, ''); // Remove all non-numeric characters except the decimal point
      this.el.nativeElement.value = value;
    }

    this.previousValue = value;
  }

  @HostListener('blur', ['$event'])
  onBlur() {
    let value: string = this.el.nativeElement.value;

    if (value && !isNaN(Number(value))) {
      // Convert the value to currency format on blur if it's valid
      value = this.formatCurrency(value);
      this.el.nativeElement.value = value;
      this.previousValue = value;  // Update the previous value with formatted currency
    } else {
      // If the value is invalid or unchanged, restore the previous value
      this.el.nativeElement.value = this.previousValue;
    }
  }

  private formatCurrency(value: string): string {
    // Convert the string to a number, and use `toLocaleString` to format it
    const numberValue = parseFloat(value.replace(/,/g, '')); // Remove commas before parsing
    if (isNaN(numberValue)) {
      return this.previousValue; // If not a number, return the previous value
    }

    return numberValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }
}