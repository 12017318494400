<app-header></app-header>

<main>
  <section class="sign-up-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <form class="sign-up-form login-width">
            <div class="sign-up-form-head">
              <h3>Forgot Password</h3>
            </div>
            <div class="sign-up-form-body">
              <div class="row">

                <div class="col-md-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line blmd-toggled">
                      <input type="password" id="password" [(ngModel)]="password" name="password" class="form-control">
                      <span toggle="#password-field" (click)="passwordClick()" class="fa fa-fw fa-eye field-icon toggle-password"></span>
                      <small *ngIf="validationErrors.password" class="error">{{ validationErrors.password}}</small>
                      <label class="blmd-label">Password <span>*</span></label>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line blmd-toggled password">
                      <input type="password" [(ngModel)]="confirmPassword" name="confirmPassword" class="form-control">
                      <small class="error" *ngIf="validationErrors.confirmPassword">{{ validationErrors.confirmPassword}}</small>
                      <label class="blmd-label">Confirm Password <span>*</span></label>
                    </div>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="customLoader" style="display:none;">
                    <img src="assets/images/loader.svg" alt="" width="40">
                  </div>
                  <button (click)="initUpdatePassword()" class="btnsignup">Reset password</button>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>

<app-footer></app-footer>
