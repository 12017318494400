import { Component, OnInit } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { BrandService } from '../../services/brand.service';
import Swal from "sweetalert2"
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';

@Component({
  selector: 'app-certificate-payment',
  templateUrl: './certificate-payment.component.html',
  styleUrls: ['./certificate-payment.component.scss']
})
export class CertificatePaymentComponent implements OnInit {
  public CartTotalPrice: any = 50;
  public payPalConfig?: IPayPalConfig;
  public CartPaypalList = [];
  public userinfo: any;

  constructor(public brandService: BrandService, private router: Router) { }

  ngOnInit(): void { 

    $('.customLoader').fadeIn();    
    this.initConfig();    
    let info = JSON.parse(localStorage.getItem('Domain_info'));
    this.CartPaypalList = [{
      unit_amount: {
        currency_code: 'USD',
        value: 50
      },
      name: info.business_name,
      quantity: 1
    }];
  }

  private initConfig(): void {

    this.payPalConfig = {
      currency: 'USD',
      clientId: 'AeZu7Ax_73ZZ88R6TSxfR9EjP76C3_hqDx4KrLSLJw6IUP3TpUc6epeNd442dLWOQL3YYXZccBp7M0aQ',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'USD',
              value: this.CartTotalPrice,
              breakdown: {
                item_total: {
                  currency_code: 'USD',
                  value: this.CartTotalPrice
                }
              }
            },
            items: this.CartPaypalList
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
        size: 'large'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {

          console.log(details);

          console.log('onApprove - you can get full order details inside onApprove: ', details);
          $('.customLoader').fadeIn();
          let info = JSON.parse(localStorage.getItem('Domain_info'));
          var obj = {
            // payment: details,
            item: this.CartPaypalList,
            order_type: 'purchase',
            payment_id: details.id,
            email: info.email,
            first_name: info.first_name,
            last_name: info.last_name,
            comment: info.comment,
            domain: info.domain,
            type: info.type,
            business_name: info.business_name,
            certificate_name: info.certificate_name,
            business_url: info.business_url,
            industry: info.industry,
            business_one_sentence: info.business_one_sentence,
            business_goals: info.business_goals,
            business_publicized: info.business_publicized,
            main_competitors: info.main_competitors,
            potential_customers: info.potential_customers,
            customers_located: info.customers_located
          }
          this.brandService.submitRequest(obj).subscribe((data: any) => {
            var res = data.response;
            if (data.status) {
              localStorage.removeItem('Domain_info');
              $('.customLoader').fadeOut();

              Swal.fire({
                title: 'Success!',
                width: 500,
                padding: '3em', 
                allowOutsideClick: false,
                allowEscapeKey: false,
                text: 'Your payment was successful. You will hear from us soon about your request. Thank You!',
                icon: 'success',
                confirmButtonText: 'Ok'
              }).then((results) => { 
                if (results.isConfirmed) {
                  window.location.href = res.data
                }
              });

             
             // Swal.fire('Success!', "Your payment was successful. You will hear from us soon about your request. Thank You!", 'success');


            } else {
              $('.customLoader').fadeOut();
              Swal.fire('Oops...', res.msg, 'error')
            }
          });
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        //  this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        Swal.fire('Oops...', 'Order cancelled, please try again.', 'error');
        console.log('OnCancel', data, actions);
      },
      onError: err => {
        Swal.fire('Oops...', 'Order cancelled, please try again.', 'error');
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    };

    $('.customLoader').fadeOut();

  }

}
