<app-header></app-header>

<main>
       
    <section class="sign-up-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <form class="sign-up-form login-width">
                        <div class="sign-up-form-head">
                            <h3>Payment</h3>
                        </div>
                        <div class="sign-up-form-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <p>Please pay a fee of <strong>$50</strong> for your certification request. Click below to facilitate the payment process.</p>
                                    <br />

                                    <div class="customLoader" style="text-align:center;display: none;">
                                        <img src="assets/images/loader.svg" alt="" style="width:40px">
                                    </div>
                                    
                                    <ngx-paypal [config]="payPalConfig"></ngx-paypal>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</main>                                    

          
       

<app-footer></app-footer>